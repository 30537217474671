import { EDIT_INTEGRATION } from "../../consts/general";

export const getCustomInitialValues = (type, integrationSecret) => {
    switch (type) {
      case EDIT_INTEGRATION.vault:
        return {
          url: integrationSecret.url || "",
          username: integrationSecret.username || "",
          password: integrationSecret.password || "",
        };
      case EDIT_INTEGRATION.jira:
        return {
          domain: integrationSecret.domain || "",
          mail: integrationSecret.mail || "",
          token: integrationSecret.token || "",
        };
      default:
        return {};
    }
  };