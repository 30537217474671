import React from "react";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import { useSelector } from "react-redux";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import AntdTableWrapper from '../../../shared/antdTableWrapper/antdTableWrapper';
import Avatar from 'antd/lib/avatar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faQuestionCircle,
  faEye,
  faShieldAlt,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";

import { getUniqeKeys } from "../../../utils/helpers";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import { dateTimeSecondsFormat } from "../../../utils/formatting";
import { useTranslation } from "react-i18next";

const renderConnectionType = (provider) => provider === "auth0" ? "email-password" : provider;

const UsersTable = ({
  data,
  loading,
  handleConfirmDelete,
  handleSelectRow,
  handleAssignRole,
  handleOnChangePage,
  currentPage,
  handleOnSort,
  handleOnFilter,
  rolesArr,
}) => {
  const { t } = useTranslation('users')
  const { user } = useAuth0();
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const totalUsers = useSelector((state) => state.profilesReducer.totalUsers);

  const usersTableColumns = [
    {
      title: t('user-management.table.headers.name'),
      dataIndex: "name",
      sorter: true,
      render: (text, record) => {
        return (
          <div style={{ wordWrap: "break-word", display: 'flex', gap: '10px' }}>
            <Avatar src={record?.picture} size="small">
              {capitalize(isEmpty(text) ? 'U' : text[0])}
            </Avatar>
            {text}
          </div>
        )
      },
    },
    {
      title: t('user-management.table.headers.role'),
      dataIndex: "role",
      filters: getUniqeKeys(rolesArr || [], "name"),
      render: (role, record) => renderRole(role),
    },
    {
      title: t('user-management.table.headers.email'),
      dataIndex: "email",
      sorter: true,
    },
    {
      title: t('user-management.table.headers.created-at'),
      dataIndex: "created_at",
      render: (created_at) => created_at ? moment(created_at).format(dateTimeSecondsFormat) : "-",
      sorter: true,
    },
    {
      title: t('user-management.table.headers.last-login'),
      dataIndex: "last_login",
      render: (last_login) =>
        last_login ? moment(last_login).format(dateTimeSecondsFormat) : "-",
      sorter: true,
    },
    {
      title: t('user-management.table.headers.emailVerification'),
      dataIndex: "email_verified",
      filters: getUniqeKeys(
        [{ email_verified: "Verified" }, { email_verified: "Not verified" }],
        "email_verified"
      ),
      render: (status) => renderStatus(status),
      className: "status-column",
    },
    {
      title: t('user-management.table.headers.connectionType'),
      dataIndex: "identities",
      width: "130px",
      sorter: false,
      render: (identities) => renderConnectionType(identities?.[0]?.provider),
    },
    {
      title: t('user-management.table.headers.actions'),
      width: "100px",
      dataIndex: "user_id",
      render: (user_id, row) => renderActionBtns(user_id, row),
    },
  ];

  // format status column values
  const renderStatus = (status) => {
    if (status) {
      return (
        <span>
          <FontAwesomeIcon icon={faCheck} className="status-icon green" />
          {t('user-management.table.body.verified')}
        </span>
      );
    }
    return (
      <span>
        <FontAwesomeIcon icon={faQuestionCircle} className="status-icon red" />
        {t('user-management.table.body.not-verified')}
      </span>
    );
  };

  // show delete button for every row
  const renderActionBtns = (user_id) => {
    if (user.sub === user_id) {
      return;
    }
    return (
      <div className="action-user-cell row">
        <IconBtn
          onClick={handleAssignRole}
          customIcon={<FontAwesomeIcon icon={faUserTag} />}
          colorClass="purple"
          disabled={isViewer}
        />
        <span className="action-user-cell__delete">
          <IconBtn
            onClick={handleConfirmDelete}
            type="delete"
            colorClass="red"
            disabled={isViewer}
          />
        </span>
      </div>
    );
  };

  const renderRole = (role) => {
    const isViewerRole = role === "Viewer";
    return (
      <span>
        <FontAwesomeIcon
          icon={isViewerRole ? faEye : faShieldAlt}
          className={`role-icon ${isViewerRole ? "green" : "purple"}`}
        />
        {role}
      </span>
    );
  };
  const handleOnGeneralChange = (_, filters, sorter, extra) => {
    const isSortChange = extra?.action === "sort";
    const isFilterChange = extra?.action === "filter";
    if (isSortChange) { 
      return handleOnSort(sorter)
    }
    if (isFilterChange) {
      const { role, email_verified } = filters;
      const newFilters = { 
        role: role?.length < rolesArr?.length ? role : [],
        email_verified,
      }
      return handleOnFilter(newFilters);
    }
  }
  return (
    <AntdTableWrapper
      loading={loading}
      rowKey="user_id"
      columns={usersTableColumns}
      dataSource={!isEmpty(data) ? data : []}
      onRow={(record) => ({
        onClick: () => {
          handleSelectRow(record);
        },
      })}
      onChange={handleOnGeneralChange}
      pagination={{
        defaultPageSize: 8,
        defaultCurrent: currentPage,
        showSizeChanger: false,
        total: totalUsers || 0,
        onChange: (pageNum) => handleOnChangePage(pageNum),
        showTotal: (total, range) => t('user-management.table.footer.items-count', { rangeFrom: range[0], rangeTo: range[1], itemsCount: total })
      }}
    />
  );
};

export default UsersTable;
