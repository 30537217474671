import { Tooltip } from "antd";
import { v4 as uuidv4 } from "uuid";
import AssetTypeImage from "../../../../../shared/assetTypeImage/assetTypeImage";
import { ReactComponent as TagIcon } from "../../../../../Images/ci/guardrails/tag.svg";
import { ReactComponent as NoTagsIcon } from "../../../../../Images/ci/no-tags.svg";
import { formatAwsStringWithUnderscore } from "../../../../../utils/formatting";

export default [
    {
        headerName:  "Asset Type",
        field: "assetType",
        flex: 2,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const type = params.row?.assetType;
          return (
             <div className="row g8">
              <AssetTypeImage assetType={type} customStyle={{ height: "16px", width: "16px", borderRadius: "4px"}}/>
              <span>{formatAwsStringWithUnderscore(type)}</span>
             </div>
          );
        },
    },
    {
        headerName: "Address",
        field: "address",
        flex: 2,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => params.row?.address,
    },
    {
        headerName: "Tags Coverage",
        field: "tagsCoverage",
        width: 130,
        sortable: false,
        filterable: false,
        align: "center",
        disableColumnMenu: true,
        renderCell: (params) => {
          const { tags = {}, showNoTags = false} = params?.row || {};
          const tagsLength = Object.keys(tags).length;
          const isTags = !showNoTags && tagsLength > 0;
          return (
          <Tooltip overlayClassName="CiStepsCollapse__table-cost-tooltip" title={isTags ?
            <div className="CiStepsCollapse__table-cost-tooltip-header col g10">
            <span className="CiStepsCollapse__table-cost-tooltip-header-title">Tags Coverage</span>
            <div className='CiStepsCollapse__table-cost-tooltip-header-content col g5'>
              {Object.keys(tags).map((tagKey = "") => (
                <span className='row g5' key={uuidv4()}>
                  <TagIcon className="img12" />
                  <span>{tagKey}: {tags[tagKey]}</span>
                </span>
              ))}
            </div>
            </div> : ""}>
            <div className={`row g5 ${isTags ? 'pointer': ''}`}>
              {isTags ? <>
                <TagIcon className="img16" />
                <span>{tagsLength} tags</span>
              </>
              : showNoTags ? <>
                <NoTagsIcon />
                <span>No tags</span>
              </> : null}
            </div>
          </Tooltip>)
        }
      },
]