import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import HeaderSearchBox from '../../shared/headerSearchBox/headerSearchBox';
import { ReactComponent as BackupDRIcon } from '../../Images/general_icons/sidebar/backup.svg';
import AppBtn from '../../shared/appBtn/appBtn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import BackupPoliciesTable from '../../components/backupDR/backupPoliciesTable';
import BackupHistoryTable from '../../components/backupDR/backupHistoryTable';
import BackupPolicyModal from '../../components/backupDR/backupPolicyModal/backupPolicyModal';

import { HISTORY_DATA_MOCK, POLICIES_DATA_MOCK } from '../../components/backupDR/backupHelper';
import { capitalizeFirst } from '../../utils/formatting';

import { useAuth0 } from '@auth0/auth0-react';
import InventoryCharts from '../../components/inventory/inventoryCharts/inventoryCharts';
import { getBaseAggregations } from '../../redux/actions/inventoryv3Actions';
import { emptyInventoryScreenFilters } from '../../consts/inventory';
import SpinnerLoader from '../../shared/spinnerLoader/spinnerLoader';
import './backupDR.scss';

const BackupDR = () => {
    const { t } = useTranslation("backupDR");
    const { user } = useAuth0();
    const [searchInput, setSearchInput] = useState("");
    const [policesMock, setPoliciesMock] = useState(POLICIES_DATA_MOCK);
    const [barLoading, setBarLoading] = useState(false);
    const [policiesData, setPoliciesData] = useState(policesMock);
    const [historyData, setHistoryData] = useState(HISTORY_DATA_MOCK);
    const [showBackupPolicyModal, setShowBackupPolicyModal] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState({});
    const [filters, setFilters] = useState([]);
    
    const dispatch = useDispatch();
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);
    const aggregationsIsManaged = useSelector((state) => state.inventoryReducer.aggregationsIsManaged);
    
    useEffect(() => {
        const isLocalStorageData = localStorage.getItem('backupDRData');
        if (isLocalStorageData) {
            const data = JSON.parse(isLocalStorageData);
            setPoliciesMock(data);
        }
        if (isEmpty(aggregationsIsManaged)) {
            fetchBaseAggs();
        }
    }, []);
    const fetchBaseAggs = async() => {
        setBarLoading(true);
        await dispatch(getBaseAggregations(emptyInventoryScreenFilters));
        setBarLoading(false);
    };
    const filteredHistoryData = useMemo(() => {
        return filters?.length ? HISTORY_DATA_MOCK.filter((elem = {}) => !!filters.some((filter) => filter?.historyId === elem.id)) : HISTORY_DATA_MOCK;
    }, [historyData, filters]);

    const checkSearch = (elem, searchInput) => {
        const { name = "", dataSource, region = "", assetType = "", createdBy = ""  } = elem || {};
        const arrayToCheck = [name.toLowerCase(), dataSource?.name?.toLowerCase(), region.toLowerCase(), assetType.toLowerCase(), createdBy.toLowerCase()];
        const found = arrayToCheck.some((string) => string.includes(searchInput.toLowerCase()));   
        return found
    }
    const filteredPoliciesData = useMemo(() => {
        return searchInput? policesMock.filter((elem = {}) => checkSearch(elem, searchInput)) : policesMock;
    }, [policesMock, searchInput]);

    const handlePolicyClick = (row) => setFilters(uniqBy([ ...filters, row ], 'historyId'));
    const handleRemoveFilter = (historyId) => setFilters(filters.filter(filter => filter.historyId !== historyId));
    const handleCreateBtnClick = () => {
        setSelectedPolicy({});
        setShowBackupPolicyModal(true);
    }
    const handlePolicyCreation = (policy) => {
        const createdBy = capitalizeFirst(user.nickname) || user.email
        const newPolicy = { ...policy, id: policesMock.length + 1, createdBy };
        setPoliciesMock([...policesMock, newPolicy]);
        localStorage.setItem('backupDRData', JSON.stringify([...policesMock, newPolicy]));
        setShowBackupPolicyModal(false);
    };
    return (
        <div className="BackupDR basic-100 col g20">
            <div className="row g10">
                <BackupDRIcon />
                <span className='bold'>{t('title')}</span>
            </div>
            <div className="basic-card row BackupDR__header g20 between">
                        <HeaderSearchBox
                            placeholder="Search..."
                            value={(val) => { setSearchInput(val)}}
                            resetSearch={(val) => setSearchInput(val)}
                            currentValue={searchInput}
                        />
                        {barLoading ? <SpinnerLoader /> : <InventoryCharts onlyIacCoverageBar aggregationsIsManaged={aggregationsIsManaged}/>}
                        <AppBtn
                            text={t("create-btn")}
                            onClick={() => handleCreateBtnClick()}
                            icon={<FontAwesomeIcon icon="plus" />}
                            disabled={isViewer}
                        />
            </div>
            {<div className='BackupDR__filters row g10'>
              {filters.map((filter = {}) => <div key={filter.historyId} className="basic-filter-badge row g5" onClick={() => handleRemoveFilter(filter.historyId)}>
                    <span>{filter.name}</span>
                    <FontAwesomeIcon icon="times"/>
                </div>)}
            </div> }
            <div className="BackupsDR__tables col">
                <BackupPoliciesTable policies={filteredPoliciesData} loading={false} handlePolicyClick={handlePolicyClick} />
                <div className="col g10">
                    <span className="bold">{t("backupHistory")}</span>
                    <BackupHistoryTable historyData={filteredHistoryData} loading={false} />
                </div>
            </div>
        {showBackupPolicyModal && <BackupPolicyModal modalVisible={showBackupPolicyModal} selectedPolicy={selectedPolicy} onClose={() => setShowBackupPolicyModal(false)} onComplete={handlePolicyCreation}/>}
        </div>
    )
};

export default BackupDR;