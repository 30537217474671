import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_INSIGHTS_BY_ACCOUNT = "GET_INSIGHTS_BY_ACCOUNT";
export const GET_INSIGHTS_AGGS = "GET_INSIGHTS_AGGS";
export const SET_INSIGHTS_FILTERS = "SET_INSIGHTS_FILTERS";
export const SET_INSIGHTS_TABLE_FILTERS = "SET_INSIGHTS_TABLE_FILTERS";
export const TOGGLE_INSIGHT_ENBALE = "TOGGLE_INSIGHT_ENBALE";
export const TOGGLE_FILTERS = "TOGGLE_FILTERS";
export const INSIGHT_SUGGESTIONS_ASSETS = "INSIGHT_SUGGESTIONS_ASSETS";
export const GET_REMEDIATION = "GET_REMEDIATION";

let resourcesAbortController;

const getInsightsByAccount = (filters, providers = []) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    if (resourcesAbortController) {
      resourcesAbortController.abort();
    }

    resourcesAbortController = new AbortController();

    const body = {
      ...filters,
      providersAcoounts: providers,
    };

    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/insights/get-insights`,
        "POST",
        body,
        undefined,
        undefined,
        undefined,
        undefined,
        resourcesAbortController.signal,
      );

      let response = [];

      if (req?.ok) {
        response = await req.json();
      }

      dispatch({
        type: GET_INSIGHTS_BY_ACCOUNT,
        payload: response,
      });
      return response;

    } catch (error) {
      if (error.name === 'AbortError') {
        return { abort: true }
      }
      return {};
    }
  });
};

const getInsightsByAggs = (filters) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/aggs`,
      "POST",
      // filters
    );

    let response = [];

    if (req?.ok) {
      response = await req.json();
    }

    dispatch({
      type: GET_INSIGHTS_AGGS,
      payload: response,
    });
    return response;
  });
};

const setInsightsFilters = (filters) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_INSIGHTS_FILTERS,
      payload: filters,
    });
  });
};

const setInsightsTableFilters = (filters) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_INSIGHTS_TABLE_FILTERS,
      payload: filters,
    });
  });
};

const toggleFilters = () => {
  return action(async (dispatch) => {
    dispatch({
      type: TOGGLE_FILTERS,
    });
  });
};

const toggelEnableInsight = (id, type) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const payload = { type }

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/toggle/${id}`,
      "PUT", payload
    );

    let result = {};

    if (req?.ok) {
      result = await req.json();

      dispatch({
        type: TOGGLE_INSIGHT_ENBALE,
        payload: result,
      });
    }
    return result;
  });
};

const getInsightsSuggestionAssets = (id, page, size, integrations = []) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/insights-suggestion-assets`,
      "POST",
      {id, page, size, integrations}
    );

    let response = [];

    if (req?.ok) {
      response = await req.json();
    }

    dispatch({
      type: INSIGHT_SUGGESTIONS_ASSETS,
      payload: response,
    });
    return response;
  });
};

const getRemediation = (data) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/get-remediations`,
      "POST",
      data
    );

    let response = [];


    if (req?.ok) {
      response = await req.json();
    }

    dispatch({
      type: GET_REMEDIATION,
      payload: response,
    });
    return response;
  });
};

export {
  getInsightsByAccount,
  getInsightsByAggs,
  setInsightsFilters,
  setInsightsTableFilters,
  toggleFilters,
  toggelEnableInsight,
  getInsightsSuggestionAssets,
  getRemediation,
};
