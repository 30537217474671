import React, { useState, useEffect } from "react";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";

import _ from "lodash";
import { Spin } from "antd";
import AppEmpty from "../appEmpty/appEmpty";

import "./tableWrapper.scss";
import { MUI_X_LICENSE_KEY } from "../../consts/config";
import CustomColumnVisibilityPanel from "../tableWrapperV2/components/columnVisibilityPanel/columnVisibilityPanel";

const TableWrapper = ({
  height,
  rowKey,
  tableData,
  columns,
  loading,
  isCheckBox,
  disableSelectionOnClick,
  pageSize,
  serverSide,
  handleFilterChange,
  handleSortChange,
  rowCount,
  selectedRowsArr,
  handlePageChange = () => null,
  movePagination,
  refresh,
  onRowClick,
  insights,
  selectedInsight,
  onPageSizeChange = () => null,
  getDetailPanelContent,
  getDetailPanelHeight,
  autoHeight,
  treeData,
  getTreeDataPath,
  disablePagination,
  hideRowCount,
  groupingColDef,
  biggerRows,
  iacTable,
  initialSortingField,
  innerTable,
  rowsPerPageOptions,
  expandedRowIds,
  className,
  headerHeight,
  rowHeight,
  hasCustomColumnMenu = false,
  tableWrapperClassName,
  controlledPage,
  ...rest
}) => {
  const [page, setPage] = React.useState(0);
  const [selectionModel, setSelectionModel] = useState([]);

  // holding the columns in a state allows the save width a location when table re-renders
  const [tableColumns, setTableColumns] = useState(columns);
  const [tableRowCount, setTableRowCount] = useState(0);

  // dynamic height
  const [currentHeight, setCurrentHeight] = useState(0);

  // for custom column menu column ui
  const defaultColumnsForVisibilityModel = (tableColumns || []).reduce((acc, col = {}) => ({ ...acc, [col.field]: true}), {});
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultColumnsForVisibilityModel);

  const initialState = {};
  if (initialSortingField) initialState.sorting = { sortModel: [{ field: initialSortingField, sort: "desc" }] }
  if (expandedRowIds) initialState.detailPanel = { expandedRowIds };

  // set inital table row count for the pagination
  useEffect(() => {
    if (!_.isEmpty(rowCount)) {
      return setTableRowCount(rowCount);
    }
  }, [rowCount]);

  useEffect(() => {
    if (refresh) {
      setTableColumns(columns);
    }
  }, [refresh]);

  // change height according to data
  useEffect(() => {
    calcHeight();
  }, [tableData]);

  // activate the library license
  LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);

  // setting a custom row key to replace table defualt id
  const handleGetRowId = (e) => {
    return e[rowKey];
  };

  // custom spinner for a loading state
  function CustomLoadingOverlay() {
    return (
      <GridOverlay>
        <div className="TableWrapper__loader center">
          <Spin />
        </div>
      </GridOverlay>
    );
  }

  // custom no data component
  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <div className="TableWrapper__empty center">
          <AppEmpty text="No Data" />
        </div>
      </GridOverlay>
    );
  }

  // custom no results after a filter component
  function CustomNoResultsOverlay() {
    return (
      <GridOverlay className="center">
        <AppEmpty customStyle="results" />
      </GridOverlay>
    );
  }

  function calcHeight() {
    if (_.isEmpty(tableData)) {
      return setCurrentHeight(height ? height : 400);
    }
    if (!_.isEmpty(tableData)) {
      let currentRowsHeight = (rowHeight ?? 32) * tableData?.length + (rowHeight ?? 32); //rows + header
      // add footer on regular tables
      if (!innerTable) {
        currentRowsHeight = currentRowsHeight + 80;
      }
      const initHeight = height ? height : 400;
      if (currentRowsHeight < initHeight) {
        setCurrentHeight(currentRowsHeight);
      } else {
        setCurrentHeight(initHeight);
      }
    }
  }
  

  return (
    <div
      style={{ height: currentHeight, width: "100%" }}
      className={`TableWrapper ${movePagination ? "movePagination" : null} ${tableWrapperClassName}`}
    >
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            getRowId={handleGetRowId}
            rows={tableData}
            columns={tableColumns}
            loading={loading}
            columnVisibilityModel={columnVisibilityModel}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: CustomNoRowsOverlay,
              NoResultsOverlay: CustomNoResultsOverlay,
              ...(hasCustomColumnMenu && 
                {ColumnMenu: menuProps => <CustomColumnVisibilityPanel
                  tableColumns={columns}
                  menuProps={menuProps}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityChange={updatedValues => setColumnVisibilityModel(updatedValues)}
                />}
              )

              // Pagination: CustomPagination, // option to create a custom pagination
            }}
            checkboxSelection={isCheckBox}
            getRowClassName={(params) =>
              insights
                ? `TableWrapper__table-row ${
                    params.row?.isNew
                      ? `new ${_.toLower(selectedInsight?.labels[0])}`
                      : ""
                  }`
                : ""
            }
            headerHeight={headerHeight ?? 36}
            rowHeight={rowHeight ?? 32}
            className={`TableWrapper__table ${biggerRows ? "bigger" : ""} ${
              iacTable ? "iacTable" : ""
            } ${onRowClick ? 'selection-sign' : ''} ${className}`}
            disableSelectionOnClick={disableSelectionOnClick}
            filterMode={!serverSide ? "client" : "server"}
            paginationMode={!serverSide ? "client" : "server"}
            sortingMode={!serverSide ? "client" : "server"}
            onFilterModelChange={handleFilterChange}
            onSortModelChange={handleSortChange}
            // pagination
            pagination={disablePagination ? false : true}
            page={controlledPage ? controlledPage - 1 : page}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [50, 100, 150]}
            onPageSizeChange={onPageSizeChange}
            rowCount={rowCount}
            onPageChange={(newPage) => {
              handlePageChange(newPage + 1);
              setPage(newPage);
            }}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = tableData.filter((row) =>
                selectedIDs.has(row[rowKey])
              );
              setSelectionModel(ids);
              selectedRowsArr(selectedRowData);
            }}
            selectionModel={selectionModel}
            onRowClick={onRowClick}
            // update manually table row sum after filtering only when client side rendering
            onStateChange={(newState) => {
              if (!serverSide) {
                setTableRowCount(newState?.containerSizes?.virtualRowsCount);
              }
            }}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            autoHeight={autoHeight}
            treeData={treeData}
            getTreeDataPath={getTreeDataPath}
            hideFooterRowCount={hideRowCount}
            groupingColDef={groupingColDef}
            initialState={initialState}
            {...rest}
          />
        </div>
      </div>
    </div>
  );
};

export default TableWrapper;
