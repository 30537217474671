export const filterSearchComperasion = (input = "", option = {}) => {
    const children = option.children?.props?.children || []; 
    if (children.length === 2) return children[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
    return children[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
export const PROVIDER_BLOCK_MOCK_ADDITION = `terraform {
  required_providers {
    aws = {
      source  = "hashicorp/aws"
      version = "5.75.1"
    }
  }
}

provider "aws" {
  region = "us-east-1"
}`