import React from "react"
import map from "lodash/map"
import isEmpty from "lodash/isEmpty"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { formatTitleByIacType } from "../../../../../utils/formatting";
import { setFilters } from "../../../../../redux/actions/iacStacksActions";
import { emptyIacScreenFilters } from "../../../../../consts/iacStacks";
import AssetInfoCard from "../assetInfoCard";
import { remoteTypes } from "../../../../../utils/icons";
import "./iacTab.scss";
import { IAC_TYPES } from "../../../../../consts/general";

const IaCTab = ({
  rowData,
  isFetchingStack,
  stacksData,
  cftStacksData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const stackClick = (stackId, stateType) => {
    let view = stateType;
    const filters = {
      ...emptyIacScreenFilters,
      stackId: [stackId],
      stateType: [stateType],
    };
    if (stateType === IAC_TYPES.opentofu) {
      filters.stateType = [stateType, IAC_TYPES.terraform];
      view = IAC_TYPES.terraform;
    }
    dispatch(setFilters(filters));
    history.push({
      pathname: `/iac`,
      search: `?view=${view}`,
    });
  };

  const renderContentName = () => {
    if (!isEmpty(cftStacksData)) {
      return "CloudFormation";
    }
    if (!isEmpty(stacksData)) {
      return formatTitleByIacType(rowData?.iacType);
    }
    return null;
  };
  const handleArgoRemoteClick = (e, stack = {}) => {
    e.stopPropagation();
    window.open(stack.metadata?.k8sMetadata?.home, "_blank");
  };
  const renderStacksList = () => {
    if (!isEmpty(stacksData)) {
      
      return (
        <div className="IaCTab__list col">
          {map(stacksData, (stack) => {
            const isArgoRemoteType = stack?.alternativeStateRemoteLocation?.argocdLocation?.appName;
            const icon = isArgoRemoteType ? "argocd" : stack?.remoteType;
            return (
              <div key={uuidv4()} className="IaCTab__list-item row">
                <div className="IaCTab__list-item-type row">
                  <img
                    src={remoteTypes(icon, themeDark)}
                    alt={stack?.remoteType}
                  />
                  <span className={`${isArgoRemoteType ? 'purple-text underline pointer' : ''}`} onClick={(e) => isArgoRemoteType && handleArgoRemoteClick(e, stack)}>{isArgoRemoteType ? stack.alternativeStateRemoteLocation.argocdLocation.appName : stack?.stateMainRemoteLocationString}</span>
                </div>
                <div
                  className="underline"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    stackClick(stack?.stackId, stack?.stateType);
                  }}
                >
                  {stack?.stateSubRemoteLocationString}
                </div>
              </div>
          )})}
        </div>
      );
    }

    if (!isEmpty(cftStacksData)) {
      return (
        <div className="IaCTab__list col">
          {map(cftStacksData, (stack) => (
            <span key={uuidv4()} className="IaCTab__list-item">
              {stack?.prettyLocation | stack?.name}
            </span>
          ))}
        </div>
      );
    }
  };

  return (
    <AssetInfoCard
      loading={isFetchingStack}
      contentArr={[
        {
          label: "Type",
          content: renderContentName(),
          display: !isEmpty(rowData?.iacType) || !isEmpty(cftStacksData),
        },
        {
          label: "Names",
          content: renderStacksList(),
          display: !isEmpty(stacksData) || !isEmpty(cftStacksData),
        },
      ]}
    />
  );
};

export default IaCTab;
