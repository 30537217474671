import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useAuth0 } from "@auth0/auth0-react";
import { Empty, Spin, Progress, Tooltip } from "antd";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import State from "../../components/dashboard/state/state";
import PieChart from "../../components/dashboard/pie/pieChart";
import IacType from "../../components/dashboard/iacType/iacType";
import Insight from "../../components/dashboard/insight/insight";
import Integration from "../../components/dashboard/integration/integration";
import MissingIntegrationsModal from "../../components/dashboard/missingIntegrationsModal/missingIntegrationsModal";
import { ReactComponent as TotalAssestsComingSoonImg } from "../../Images/dashboard/totalAssetsComingSoon.svg";
import {
  getClassifications,
} from "../../redux/actions/classificationsActions";
import { getDashboardInsights } from "../../redux/actions/insightsActions";
import {
  getDashboardBaseAggregations,
  getDashboardFireflyAggregations,
  setOnboardingComplete,
  setOnlyProd,
  setProdAccounts,
  getAccountTotalAssets,
} from "../../redux/actions/dashboardActions";
import { getAccountIntegrations } from "../../redux/actions/onbordingActions";
import { getIacStacksBaseAggs } from "../../redux/actions/iacStacksActions";
import Loading from "../../shared/loading/loading";
import {  numberWithCommas } from "../../utils/formatting";
import { providers } from "../../utils/providers";
import { emptyIacScreenFilters } from "../../consts/iacStacks";
import TopFiveProperties from "../../components/dashboard/topFiveProperties/topFiveProperties";
import Top5UnmanagedResources from "../../components/dashboard/top5UnmanagedResources/top5UnmanagedResources";
import { git_integrations } from "../integrationsCatalog/integrations_index";
import ProdToggle from "../../shared/prodToggle/prodToggle";
import ProgressBar from "../../shared/progressBar/progressBar";

import { emptyInventoryScreenFilters } from "../../consts/inventory";
import { filterByIds, getMissingIntegrationsValue, getProviderCount, getSortedIntegrations, getStatesStatistics } from "./utils";
import CloudWorkflows from "../../components/dashboard/cloudWorkflows/cloudWorkflows";
import { getAllClouds } from "../../redux/actions/globalAppActions";
import OtherTypes from "../../components/dashboard/otherTypes/otherTypes";
import { IAC_TYPES } from "../../consts/general";

import "./dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setInsightsFilters } from "../../redux/actions/insightsV2Actions";
import { getProdIntegratioNumbers } from "../../components/inventory/utils";
import LottieLoadingPai from "../../shared/lottieLoading/lottieLoadingPai";
const CUBES_IAC_TYPES = [IAC_TYPES.terraform, IAC_TYPES.helm, IAC_TYPES.cloudformation, IAC_TYPES.kustomize, IAC_TYPES.pulumi, IAC_TYPES.k8s];

const Dashboard = () => {
  const { user } = useAuth0();
  const { t } = useTranslation("dashboard");
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [datesData, setDatesData] = useState([]);
  const [regionsData, setRegionsData] = useState([]);
  const [showRegions, setShowRegions] = useState(false);
  const [insightsData, setInsightsData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [sortedSteps, setSortedSteps] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [missingIntegrationsModalData, setMissingIntegrationsModalData] = useState(null);
  const [missingIntegrationsModalOpen, setMissingIntegrationsModalOpen] = useState(false);

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );
  
  const aggregationsIsManaged = useSelector(
    (state) => state.dashboardReducer.aggregationsIsManaged
  );

  const aggregationsAssetsVolume = useSelector(
    (state) => state.dashboardReducer.aggregationsAssetsVolume
  );

  const aggregationsRegion = useSelector(
    (state) => state.dashboardReducer.aggregationsRegion
  );

  const aggregationsClassificaition = useSelector(
    (state) => state.dashboardReducer.aggregationsClassificaition
  );

  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );

  const onlyProd = useSelector((state) => state.dashboardReducer.onlyProd);

  const prodAccountNumbers = useSelector(
    (state) => state.dashboardReducer.prodAccountNumbers
  );

  const accountIntegrations = useSelector(
    (state) => state.onbordingReducer.integrations
  );

  const excludeMissingIntegrations = useSelector(
    (state) => state.onbordingReducer.account.disableFeatures?.excludeMissingIntegrations
  ) || [];

  const aggregationsMissingAccountNumbers = useSelector(
    (state) => state.iacStacksReducer.aggregationsMissingAccountNumbers
  ) || {};

  const aggregationsStateTypeStatus = useSelector(
    (state) => state.iacStacksReducer.aggregationsStateTypeStatus
  );

  const accountTotals = useSelector(
    (state) => state.dashboardReducer.accountTotals
  );

  const insightFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );

  const { statesData = {}, managedGraphData, totalAssetsCount, formattedGraphData, isZeroPercentage, otherTypesData } = useMemo(() => getStatesStatistics(aggregationsIsManaged), [aggregationsIsManaged]);
  const showManagedGraph = !isEmpty(managedGraphData);

  const onbordingSteps = [
    {
      text: "Data sources",
      key: "provider",
    },
    {
      text: "IaC Tools",
      key: "iac",
    },
    {
      text: "GitOps",
      key: "gitOps",
    },
    {
      text: "ChatOps",
      key: "notifications",
    },
    {
      text: "Done",
      key: "done",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (initLoading) {
      fetchInitData();
    } else {
      fetchData();
    }
  }, [onlyProd]);

  useEffect(() => {
    if (!initLoading && !loading) {
      handleSetProgress();
      getNumbersForCounters();
      handleArrangeRegion();
      handleArrangeClassifications();
      handleSetProdAccount();
    }
  }, [initLoading, loading]);

  const handleSetProgress = async () => {
    if (!isEmpty(accountIntegrations)) {
      let integrations = accountIntegrations;
      // remove provider integrations
      integrations = _.mapKeys(integrations, (val, integKey) => {
        if (!_.keys(providers)?.includes(integKey)) {
          return integKey;
        }
      });
      // group by sections
      const gitOpsTypes = _.map(git_integrations, (integ) => integ?.name);
      let groupIntegrations = {
        gitOps: false,
        notifications: false,
        done: false,
      };
      integrations = _.mapKeys(integrations, (val, integKey) => {
        if (integKey === "notifications" && !isEmpty(val)) {
          groupIntegrations["notifications"] = true;
        }
        if (
          (gitOpsTypes?.includes(integKey) || integKey === "git") &&
          !isEmpty(val)
        ) {
          groupIntegrations["gitOps"] = true;
        }
      });
      // sort steps by active or inactive
      let sortedGroupIntegrations = [
        { key: "gitOps", active: groupIntegrations["gitOps"] },
        { key: "notifications", active: groupIntegrations["notifications"] },
        { key: "done", active: false },
      ];
      if (_.some(_.values(groupIntegrations), (val) => !val)) {
        let sorted = [];
        _.mapValues(groupIntegrations, (val, key) => {
          if (val) {
            return sorted?.unshift({ key, active: val });
          }
          sorted?.push({ key, active: val });
        });
        sortedGroupIntegrations = sorted;
      }
      sortedGroupIntegrations?.unshift([
        { key: "provider", active: true },
        { key: "iac", active: true },
      ]);
      sortedGroupIntegrations = _.flatten(sortedGroupIntegrations);
      // add titles
      const sortedSteps = _.map(sortedGroupIntegrations, (step) => {
        const match = onbordingSteps?.find((st) => st?.key === step?.key);
        return { ...step, text: match ? match?.text : "" };
      });
      const firstFalseIndex = sortedSteps?.findIndex((step) => !step?.active);
      // if all steps are active
      if (
        firstFalseIndex === -1 ||
        (!_.isEmpty(sortedSteps[firstFalseIndex]) &&
          sortedSteps[firstFalseIndex]?.key === "done")
      ) {
        await dispatch(setOnboardingComplete(true));
        return setShowOnboarding(false);
      }
      await dispatch(setOnboardingComplete(false));
      setCurrentStep(firstFalseIndex + 1);
      setSortedSteps(sortedSteps);
      setShowOnboarding(true);
    }
  };

  const getNumbersForCounters = () => {
    let output = [];
    if (!_.isEmpty(aggregationsAssetsVolume)) {
      output = aggregationsAssetsVolume?.[0]?.state?.buckets;
    }
    return setDatesData(output);
  };

  const handleArrangeRegion = () => {
    let match = aggregationsRegion?.find((agg) => agg.key === "aws");
    if (!_.isUndefined(match)) {
      setRegionsData(match.regions?.buckets);
    }
    setShowRegions(true);
  };

  const handleArrangeClassifications = () => {
    let categories = { Optimization: 0, Misconfiguration: 0, Reliability: 0 };
    _.map(aggregationsClassificaition, (accountClassification) => {
      let match = classifications?.find(
        (dbClassification) => dbClassification.id === accountClassification.key
      );
      let labels = match ? match.labels : [];
      _.map(labels, (label) => {
        if (Object.keys(categories)?.includes(label)) {
          categories[label] += 1;
        }
      });
    });
    setInsightsData(categories);
  };

  const fetchInitData = async () => {
    setInitLoading(true);
    await getAllData();
    setInitLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    await getAllData();
    setLoading(false);
  };

  const getAllData = async () => {
    const fields = "accountId,isProd"
    await Promise.all([
      dispatch(getAccountIntegrations(fields)),
      dispatch(
        getDashboardBaseAggregations(
          emptyInventoryScreenFilters,
          "",
          false,
          onlyProd
        )
      ),
      dispatch(getDashboardFireflyAggregations(onlyProd)),
      dispatch(getClassifications()),
      dispatch(getDashboardInsights(onlyProd)),
      dispatch(getIacStacksBaseAggs(emptyIacScreenFilters, "", onlyProd, true)),
      dispatch(getAccountTotalAssets(onlyProd)),
      dispatch(getAllClouds()),
    ]);
  };

  const handleOpenMissingIntegrations = async (data) => {
    await setMissingIntegrationsModalData(data);
    setMissingIntegrationsModalOpen(true);
  };

  const renderStatus = () => {
    const percentage = ((currentStep - 1) / (sortedSteps?.length - 1)) * 100;
    const match = sortedSteps[currentStep - 1];
    return (
      <div className="Dashboard__onBoarding-card-profile-status col">
        <span className="Dashboard__onBoarding-card-profile-status-header bold">{`Your onboarding is ${percentage}% complete`}</span>
        <span>{`To do: Step ${currentStep} | ${match?.text}`}</span>
      </div>
    );
  };

  const renderIntegrations = () => {
    const prodIntegrationNumbers = getProdIntegratioNumbers(providerIntegrations)
    const integrations = onlyProd ? filterByIds(providerIntegrations, prodIntegrationNumbers) : providerIntegrations
    
    const cloudProviders = getProviderCount(integrations, t) || {}
    const sortedIntegrations = getSortedIntegrations(cloudProviders)
    return _.map(Object.keys(sortedIntegrations), (provider, index) => {
      const isSaas = provider === "saas";
      const missingIntegrationsValue = getMissingIntegrationsValue(provider, aggregationsMissingAccountNumbers, excludeMissingIntegrations, isSaas);
      return (
        <Integration
          key={provider + index}
          provider={provider}
          count={cloudProviders[provider]?.count}
          name={cloudProviders[provider]?.name}
          missingIntegrations={missingIntegrationsValue}
          onlyProd={onlyProd}
          prodAccounts={prodAccountNumbers}
          openMissingIntegrations={() =>
            handleOpenMissingIntegrations({
              provider,
              count: cloudProviders[provider]?.count,
              missingIntegrations: missingIntegrationsValue
            })
          }
        />
      );
    });
  };

  const renderStates = () => {
    return _.map(Object.keys(statesData), (state) => {
      return (
        <State
          key={uuidv4()}
          name={state}
          percentage={statesData[state]?.percentage}
          datesData={datesData}
          total={statesData[state]?.total || 0}
          onlyProd={onlyProd}
          prodAccounts={prodAccountNumbers}
        />
      );
    });
  };

  const renderTypes = () => {
    if (!_.isEmpty(aggregationsStateTypeStatus)) {
      return _.map(CUBES_IAC_TYPES, (type) => {
        const statusAgg = aggregationsStateTypeStatus?.find(
          (iacTypeAgg) => iacTypeAgg?.key === type
        ) || { key: type, doc_count: 0 };
        const iacTypeStatuses = statusAgg?.statuses?.buckets;
        const outOfSync =
          iacTypeStatuses?.find((status) => status?.key === "outOfSync")
            ?.doc_count || 0;
        const partialSync =
          iacTypeStatuses?.find((status) => status?.key === "partialSync")
            ?.doc_count || 0;
        const corrupted =
          iacTypeStatuses?.find((status) => status?.key === "corrupted")
            ?.doc_count || 0;
        const syncIssues = outOfSync + partialSync + corrupted;
        return (
          <IacType
            key={uuidv4()}
            type={statusAgg}
            syncIssues={syncIssues || 0}
            onlyProd={onlyProd}
            prodAccounts={prodAccountNumbers}
          />
        );
      });
    }
    return renderEmptyNoData();
  };

  const renderEmptyNoData = (returnOnlyEmptyImage = false) => (
    <div className={`${returnOnlyEmptyImage ? '' : 'basic-card '}center basic-100`}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
    </div>);
  
  const renderInsights = () => {
    if (!_.isEmpty(insightsData)) {
      return _.map(Object.keys(insightsData), (category, index) => {
        return (
          <Insight
            key={category + index}
            category={category}
            count={insightsData[category]}
            onlyProd={onlyProd}
            prodAccounts={prodAccountNumbers}
          />
        );
      });
    }
  };

  const handleSetProdAccount = () => {
    if (!isEmpty(providerIntegrations)) {
      // arrange all the data to be a flat array of objects
      let allProviders = _.map(_.keys(providerIntegrations), (providerKey) => {
        return _.map(providerIntegrations?.[providerKey] || [], (integ) => {
          return {
            ...integ,
            provider: providerKey,
          };
        });
      });
      allProviders = _.flatten(allProviders);

      // filter data to find only "isProd" objects
      const prodAccounts = _.filter(allProviders, (integ) => integ?.isProd);

      // get only account numbers or ClusterId for K8S obj
      const prodAccountNumbers = _.map(prodAccounts || [], (item) => {
        return item?.accountNumber || item?.clusterId;
      });
      return dispatch(setProdAccounts(prodAccountNumbers));
    }
  };

  if (initLoading) {
    return (
      <div className="basic-page center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="Dashboard col">
      {showOnboarding && (
        <div className="Dashboard__onBoarding col">
          <span className="muted">Onboarding Status</span>
          <div className="Dashboard__onBoarding-card basic-card row between">
            <div className="Dashboard__onBoarding-card-profile row">
              <Progress
                type="circle"
                strokeColor={{
                  "0%": "#7F6AF4",
                  "100%": "#88E3E4",
                }}
                percent={((currentStep - 1) / (sortedSteps?.length - 1)) * 100}
                width={65}
                trailColor="#ffffff"
              />
              <div className="Dashboard__onBoarding-card-profile-img">
                <div
                  className="Dashboard__onBoarding-card-profile-img-inner"
                  style={{ backgroundImage: `url(${user?.picture})` }}
                />
              </div>
              {renderStatus()}
            </div>
            <ProgressBar
              steps={sortedSteps}
              currentStep={currentStep}
              customMargin="0 10%"
              customWidth={25}
              customStyle
            />
          </div>
        </div>
      )}
      <ProdToggle
        value={onlyProd}
        setStoreValue={(val) => dispatch(setOnlyProd(val))}
        setInsightsFilterProd={(val) => dispatch(setInsightsFilters({ ...insightFilters, "onlyProduction": val }))}
        disabled={isEmpty(prodAccountNumbers)}
        location="homepage dashboard"
      />
      {loading ? (
        <div className="basic-page center">
          <Loading />
        </div>
    ) : (
        <div className="Dashboard col">
          <div className="Dashboard__top">{renderIntegrations()}</div>
          <div className="Dashboard__center">
            <div className="Dashboard__center-container col">
                <span className="muted">Cloud Waste</span>
                <CloudWorkflows/>
            </div>
            <div className="Dashboard__center-container col">
              <span className="muted">{t("assetsCube.title")}</span>
              <div className="no-flex-card col g10 Dashboard__center-container-assets-iacCoverage">
                <span className="Dashboard__center-container-assets-iacCoverage-title muted">{t("assetsCube.subtitle")}</span>
              {showManagedGraph ? (
                <div className="Dashboard__center-container-assets row center">
                  <div className="Dashboard__center-container-assets-graph">
                    <PieChart
                      data={formattedGraphData}
                      totalAssetsCount={totalAssetsCount}
                      datesData={datesData}
                      isZeroPercentage={isZeroPercentage}
                    />
                  </div>
                    <div className="Dashboard__center-container-assets-states">
                      {renderStates()}
                    </div>
                </div>) : <LottieLoadingPai />}
              </div>
              <div className="Dashboard__center-totalOther basic-100 row">
                <div className="Dashboard__center-total no-flex-card col between basic-100">
                  <div className="col g10">
                    <div className="row g10">
                     <span className="muted">{t("assetsCube.totalAssets")}</span>
                     <Tooltip title={t("assetsCube.tooltip")} placement="right">
                      <FontAwesomeIcon icon="info" className="Dashboard__center-total-info pointer" />
                    </Tooltip>
                    </div>
                    <span className="Dashboard__center-total-text gradient-text">{_.isNumber(accountTotals?.count) ? numberWithCommas(accountTotals?.count) : 0}</span>
                  </div>
                  <div className="Dashboard__center-total-coming">
                    <TotalAssestsComingSoonImg />
                    <span className="Dashboard__center-total-coming-text">{t("assetsCube.comingSoonExp")}</span>
                  </div>
                </div>
                <div className="Dashboard__center-otherTypes no-flex-card col g10 basic-100">
                  <span className="muted">{t("assetsCube.otherTypes.title")}</span>
                  <OtherTypes otherTypesData={otherTypesData} />
                </div>
              </div>
            </div>
            <div className="Dashboard__center-container col">
              <span className="muted">IaC Stacks</span>
              <div className="Dashboard__center-container-iac">
                {renderTypes()}
              </div>
            </div>
          </div>
          <div className="Dashboard__centerBottom">
            <div className="Dashboard__centerBottom-container col">
              <span className="muted">Top 5 Drifted Properties</span>
              <TopFiveProperties />
            </div>
            <div className="Dashboard__centerBottom-container col">
              <span className="muted">Top 5 Unmanaged Cloud Resources</span>
              <Top5UnmanagedResources onlyProd={onlyProd} prodAccounts={prodAccountNumbers} />
            </div>
          </div>
          <MissingIntegrationsModal
              visible={missingIntegrationsModalOpen}
              handleClose={() => {
                setMissingIntegrationsModalData(null);
                setMissingIntegrationsModalOpen(false);
              }}
              data={missingIntegrationsModalData}
          />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
