import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import SplashScreen from '../../shared/splashScreen/splashScreen';
import WelcomeBlockUsers from "./../../components/welcomeBlockUsers/welcomeBlockUsers";
import "./createAccount.scss";

const CreateAccount = () => {
    const { logout, user } = useAuth0();
    const [isBlockUserScreen, setIsBlockUserScreen] = useState(false);

    useEffect(() => {
        postEmailAsCompayName();
    }, [user]);

    const postEmailAsCompayName = async () => {
        const requestWrapper = new RequestWrapper();
    
        try {
            const response = await requestWrapper.sendRequest(`${BACKEND_URL}/account`, "PUT", {}, true, null, false, 300, null, true);
            if (response.status === 403){
                const errorJson = await response.json() || {}
                if(errorJson?.message === "You are not authorized to join this account.") {
                    setIsBlockUserScreen(true)
                    return
                }
            }
            return window.location.reload(false);
        } catch (error) {
            logout()
        }
    }

    const blockUserScreenText = () => {
        return (
            <>
                <div className="welcome-block-user-card__text-container">
                    <div className="welcome-block-user-card__description-container" style={{rowGap:16}}>
                        <div className="welcome-block-user-card__description-container__sub-title">
                            <b>Hello there!</b>
                        </div>
                        <div>
                            It looks like you're trying to access Firefly using your company email, but an invitation is required to join your organization's Firefly account.
                        </div>
                        <div>
                            We've notified your administrators, and they'll review your request shortly.
                        </div>
                        <div>
                            Once invited, you'll receive an email with access details.
                        </div>
                        <div>
                            If you have questions, please reach out to support@firefly.ai.
                        </div>
                    </div>
                </div>
            </>
        )
    }


    return (
        isBlockUserScreen ? <WelcomeBlockUsers text={blockUserScreenText()} height={630}/> : <SplashScreen />
    );
};

export default CreateAccount;
