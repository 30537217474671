import React from "react";
import { providers } from "../../../../utils/providers";
import { CODIFY_TYPES, IAC_TYPES, PROVIDERS } from "../../../../consts/general";
import { Tabs, Tooltip } from "antd";
import { useSelector } from "react-redux";
import TerraformCodification from "../../terraformCodification/terraformCodification";
import SmartCodifyTab from "../../smartCodifyTab/smartCodifyTab";
import { terraformK8sResources } from "../../../../utils/resources";
import { formatCodifyTabName } from "../../../../utils/formatting";
import { CODIFY_LANGUAGES } from "../codifyHelpers";
import LanguageSwitcher from "../../../../shared/languageSwitcher/languageSwitcher";

const { TabPane } = Tabs;

const CodifyTabs = ({ onChangeTab, handleSetActiveSmartTab, isFetchingIaC, codifySessionId, setIsOverweightResponse, 
    selectedResources = [], renderTabContent, setActiveLanguage, fetchIacImport, mapData, isLoadingOtherIac, fetchNewCodify }) => {
    const iacImportState = useSelector((state) => state.iacImportReducer) || {};
    const { terraformIacData, k8sIacData, isImportBlocksClicked, activeIacTab: activeTab, activeCodificationType, activeLanguage, otherIacData } = iacImportState;
    const provider = selectedResources[0]?.provider || "";
    const getCodifyTabByProvider = (provider = '', isMultiLanguage = false) => {
        const title = formatCodifyTabName(provider)
        const languagesArr = provider === CODIFY_TYPES.googleCloudSdk ? CODIFY_LANGUAGES.slice(0,3)
        : ([CODIFY_TYPES.tfcdk, CODIFY_TYPES.cdk, CODIFY_TYPES.cdkl2].includes(provider) ? CODIFY_LANGUAGES
        : CODIFY_LANGUAGES.slice(0, 4));
        
        if (provider === CODIFY_TYPES.terraform || provider === CODIFY_TYPES.tfcdk) {
          return (
            <TabPane tab={renderTabContent(title, provider)} key={provider}>
              <div className={`CodifyDrawer__content-body ${isMultiLanguage ? 'switcherTab' : ''}`}>
                  {isMultiLanguage &&
                    <LanguageSwitcher
                      activeTab={activeLanguage}
                      handleSetActiveTab={(lang) => {
                      setActiveLanguage(lang);
                      fetchIacImport(provider, lang)
                      }}
                      typeArr={languagesArr} />
                    }
                    <SmartCodifyTab
                      codifyType={provider}
                      handleSetActiveTab={(type) =>
                        handleSetActiveSmartTab(type, provider, provider === CODIFY_TYPES.tfcdk ? activeLanguage : null)
                      }
                      isFetchingIaC={isFetchingIaC}
                      iacData={mapData[provider]}
                      activeType={activeCodificationType}
                      selectedResources={selectedResources}
                      isImportBlocksClicked={isImportBlocksClicked}
                      handleOverweightData={(isOverweight) => setIsOverweightResponse(isOverweight)}
                      codifySessionId={codifySessionId}
                    />
                  </div>
            </TabPane>
          )
        }
        return (
          <TabPane tab={renderTabContent(title, provider)} key={provider}>
            <div className={`CodifyDrawer__content-body ${isMultiLanguage ? 'switcherTab' : ''}`}>
           {isMultiLanguage &&
                <LanguageSwitcher
                  activeTab={activeLanguage}
                  handleSetActiveTab={(lang) => {
                    setActiveLanguage(lang);
                    fetchNewCodify(provider, lang, true)
                  }}
                  typeArr={languagesArr}
                  isFetchingIaC={isLoadingOtherIac}/>
            }
            <SmartCodifyTab
                codifyType={provider}
                handleSetActiveTab={(type) => activeTab === CODIFY_TYPES.crossplane ? handleSetActiveSmartTab(type, provider) : null}
                isFetchingIaC={isLoadingOtherIac}
                iacData={otherIacData?.[provider]}
                activeType={activeCodificationType}
                selectedResources={selectedResources}
                hideNonNaiveTypes
                isCodifyAI
                handleOverweightData={(isOverweight) => setIsOverweightResponse(isOverweight)}
                codifySessionId={codifySessionId}
              />
            </div>
          </TabPane>
        )
      } 
    if (providers[provider]?.codifyDefault === CODIFY_TYPES.terraform) {
        const isAzureProvider = provider === PROVIDERS.azurerm;
        const isAWSProvider = provider === PROVIDERS.aws;
        const isGCPProvider = provider === PROVIDERS.gcp;
        
        return (
            <Tabs
              defaultActiveKey={CODIFY_TYPES.terraform}
              onChange={onChangeTab}
              activeKey={activeTab}
              tabBarStyle={{ padding: "0" }}
            >
              {getCodifyTabByProvider(CODIFY_TYPES.terraform)}
              {getCodifyTabByProvider(CODIFY_TYPES.tfcdk, true)}
              {getCodifyTabByProvider(CODIFY_TYPES.pulumi, true)}
              {isAWSProvider && <>
                {getCodifyTabByProvider(CODIFY_TYPES.cloudformation)}
                {getCodifyTabByProvider(CODIFY_TYPES.cdk, true)}
                {getCodifyTabByProvider(CODIFY_TYPES.cdkl2, true)}
                {getCodifyTabByProvider(CODIFY_TYPES.crossplane)}
                {getCodifyTabByProvider(CODIFY_TYPES.ansible)}
              </>}
              {isGCPProvider && <>
                {getCodifyTabByProvider(CODIFY_TYPES.configConnector)}
                {getCodifyTabByProvider(CODIFY_TYPES.googleCloudSdk, true)}
                {getCodifyTabByProvider(CODIFY_TYPES.crossplane)}
                {getCodifyTabByProvider(CODIFY_TYPES.ansible)}
                </>}
               {isAzureProvider && <>
                {getCodifyTabByProvider(CODIFY_TYPES.arm)}
                {getCodifyTabByProvider(CODIFY_TYPES.bicep)}
                {getCodifyTabByProvider(CODIFY_TYPES.crossplane)}
                {getCodifyTabByProvider(CODIFY_TYPES.ansible)}
                </>}
         </Tabs>
        );
      }
      //k8s
      const isK8sTerraformDisabled = selectedResources.every((item) => terraformK8sResources.includes(item?.assetType)) ? false : true;
      return (
        <Tabs
          defaultActiveKey="Manifest"
          onChange={onChangeTab}
          activeKey={activeTab}
          tabBarStyle={{ padding: "0" }}
        >
          <TabPane tab={renderTabContent("Manifest", CODIFY_TYPES.manifest)} key={IAC_TYPES.k8s}>
            <div className="CodifyDrawer__content-body center">
              <TerraformCodification
                isFetchingIaC={isFetchingIaC}
                iacData={k8sIacData}
                language="yaml"
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <span>
                {isK8sTerraformDisabled ? (
                  <Tooltip placement="top" title="Not Supported">
                    {renderTabContent("Terraform", CODIFY_TYPES.terraform)}
                  </Tooltip>
                ) : renderTabContent("Terraform", CODIFY_TYPES.terraform)}
              </span>
            }
            key={CODIFY_TYPES.terraform}
            disabled={isK8sTerraformDisabled}
          >
            <div className={`CodifyDrawer__content-body`}>
              <SmartCodifyTab
                codifyType={CODIFY_TYPES.terraform}
                handleSetActiveTab={(type) => handleSetActiveSmartTab(type, CODIFY_TYPES.terraform)}
                isFetchingIaC={isFetchingIaC}
                iacData={terraformIacData}
                activeType={activeCodificationType}
                selectedResources={selectedResources}
                hideNonNaiveTypes={true}
                isImportBlocksClicked={isImportBlocksClicked}
                handleOverweightData={(isOverweight) => setIsOverweightResponse(isOverweight)}
                codifySessionId={codifySessionId}
              />
            </div>
          </TabPane>
          {getCodifyTabByProvider(CODIFY_TYPES.pulumi, true)}
          {getCodifyTabByProvider(CODIFY_TYPES.helm)}
          {getCodifyTabByProvider(CODIFY_TYPES.cdk8s, true)}
        </Tabs>
      );
}

export default CodifyTabs;
