const TABS_KEYS = {
    general: 'general',
    config: 'config',
    history: 'history',
    trace: 'trace',
    comments: 'comments',
    relationship: 'relationship',
    eventViewer: 'eventViewer',
}
const TITLES = {
    dataSource: 'Data Source',
    dataSources: 'Data Sources',
    dataSourceStatus: 'Data Source Status',
    firefly: 'Firefly - Cloud Asset Management',
    link: 'Link to the asset',
}
const IAC_STACKS_MAP = {
    terraform: 'Terraform',
    cloudformation: 'CloudFormation',
    pulumi: 'Pulumi',
    helm: 'Helm',
    k8s: 'Argo Directory',
    kustomize: 'Kustomize',
}
const DESCRIPTIONS = {
    awsEventDriven: 'Using the Event-driven method for fetching your IaC stacks ensures that Firefly refreshes your assets in the inventory in near real-time as soon as they change. This method is the optimal choice for maintaining an up-to-date inventory of your assets. To use the event-driven service, To use the event-driven service, enable CloudTrail in your AWS account.',
    awsEventDrivenNote: 'Note: To use the event-driven service, enable CloudTrail in your AWS account.',
    gcpEventDriven:'Using the Event-driven method for fetching your cloud resources ensures that Firefly refreshes your assets in the inventory in near real-time as soon as they change. This method is the optimal choice for maintaining an up-to-date inventory of your assets.'
}

const PROVIDERS = {
    aws: 'aws',
    github: 'github',
    gitlab: 'gitlab',
    bitbucket: 'bitbucket',
    codecommit: 'codecommit',
    ns1: 'ns1',
    pagerduty: 'pagerduty',
    mongodbatlas: 'mongodbatlas',
    azurerm: 'azurerm',
    azure: 'azure',
    gcp: 'gcp',
    GCP: 'GCP',
    googlechat: 'google-chat',
    webex: 'webex',
    env0: 'env0',
    jira: 'jira',
    cloudflare: 'cloudflare',
    slack: 'slack',
    opsgenie: 'opsgenie',
    newrelic: 'newrelic',
    okta: 'okta',
    datadog: 'datadog',
    teams: 'teams',
    torq: 'torq',
    webhooks: 'webhooks',
    vault: 'vault',
}
const IAC_TYPES = {
    terraform: 'terraform',
    cloudformation: 'cloudformation',
    pulumi: 'pulumi',
    helm: 'helm',
    kustomize: 'kustomize',
    k8s: 'k8s',
    opentofu: 'opentofu',
}

const K8S_IAC_TYPES = ['kustomize', 'k8s'];
const ALL_IAC_TYPES = Object.keys(IAC_TYPES);
const VCS_TYPES = {
    github: 'github',
    gitlab: 'gitlab',
    bitbucket: 'bitbucket',
    codecommit: 'codecommit',
    azuredevops: 'azuredevops',
}
const CODIFY_TYPES = {
    ansible: 'ansible',
    terraform: 'terraform',
    cloudformation: 'cloudformation',
    pulumi: 'pulumi',
    crossplane: 'crossplane',
    cdk: 'cdk',
    cdkl2: 'cdkl2',
    googleCloudSdk: 'googleCloudSdk',
    deploymentManager: 'deploymentManager',
    configConnector: 'configConnector',
    helm: 'helm',
    cdk8s: 'cdk8s',
    manifest: 'manifest',
    tfcdk: "tfcdk",
    arm: "arm",
    bicep: "bicep",
};
const RESOURCE_STATE_TYPES = {
    managed: 'managed',
    unmanaged: 'unmanaged',
    modified: 'modified',
    ghost: 'ghost',
    deleted: 'deleted',
    pending: 'pending',
    undetermined: 'undetermined',
    child: 'child',
    iacIgnored: 'iacIgnored',
}

const CLOUD_PROVIDERS = {
    aws: 'aws',
    gcp: 'gcp',
    k8s: 'k8s',
    k8sIntegration: 'k8s integration',
    kubernetes: 'kubernetes',
    azurerm: 'azurerm',
    azure: 'azure',
}

const PROVIDERS_TITLE_MAP = {
    aws: 'AWS',
    gcp: 'Google Cloud',
    k8s: 'Kubernetes',
    azurerm: 'Azure',
    akamai: 'Akamai',
    datadog: 'Datadog',
    okta: 'Okta',
    github: 'GitHub',
    gitlab: 'GitLab',
    bitbucket: 'Bitbucket',
    azuredevops: 'Azure DevOps',
    codecommit: 'AWS CodeCommit',
    newrelic: 'New Relic',
    cloudflare: 'Cloudflare',
    pagerduty: 'PagerDuty',
    ns1: 'NS1',
    mongodbatlas: 'MongoDB Atlas',
    slack: 'Slack',
    torq: 'Torq',
    opsgenie: 'Opsgenie',
    webhooks: 'Webhooks',
    env0: 'env0',
    jira: 'Jira',
    teams: 'Teams',
    vault: 'Vault',
}
const PROVIDERS_RECOMMENDATION = {
    gcp: 'gcpRecommendation',
}

const AZURE_DISK_NAMES = [
    "Azure unassociated public IP addresses",
    "Azure unattached managed disks",
    "Azure managed disks attached to stopped Virtual Machines",
    "Azure unassociated public IP addresses"
  ];

  const ACOOUNT_PROVIDERS = {
    aws: "aws",
    k8s: "kubernetes",
    akamai: "akamai",
    github: "github",
    gcp: "google",
    azurerm: "azurerm",
    cloudflare: 'cloudflare',
    newrelic: 'newrelic',
    datadog: 'datadog',
    okta: 'okta',
    ns1: 'ns1',
    mongodbatlas: 'mongodbatlas',
    pagerduty: 'pagerduty',
    tfc: 'tfc',
    vault: 'vault',
}

const EDIT_INTEGRATION = {
    vault: 'vault',
    jira: 'jira',
}

// features by providers/types/....etc
const PROVIDERS_SUPPORT_EVENT_VIEWER = [CLOUD_PROVIDERS.aws, CLOUD_PROVIDERS.gcp, CLOUD_PROVIDERS.azurerm];
const CLOUD_PROVIDERS_SUPPORT = Object.values(CLOUD_PROVIDERS);
const EMPTY_CI_WORKFLOWS_AGGS_FILTERS = { repos: [], ciTool: [], status: [], workspaceId: [], labels: [] };

export {
    TABS_KEYS,
    TITLES,
    IAC_STACKS_MAP,
    DESCRIPTIONS,
    PROVIDERS,
    K8S_IAC_TYPES,
    ALL_IAC_TYPES,
    IAC_TYPES,
    VCS_TYPES,
    CODIFY_TYPES,
    RESOURCE_STATE_TYPES,
    CLOUD_PROVIDERS,
    PROVIDERS_SUPPORT_EVENT_VIEWER,
    CLOUD_PROVIDERS_SUPPORT,
    EMPTY_CI_WORKFLOWS_AGGS_FILTERS,
    PROVIDERS_TITLE_MAP,
    PROVIDERS_RECOMMENDATION,
    AZURE_DISK_NAMES,
    ACOOUNT_PROVIDERS,
    EDIT_INTEGRATION,
}