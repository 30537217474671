import React, { useEffect } from "react";
import isEmpty from 'lodash/isEmpty';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import TableWrapper from "../../shared/tableWrapper/tableWrapper";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { sendEvent } from "../../utils/amplitude";
import { ReactComponent as PullRequestIcon } from "../../Images/ci/icons/ci-pull-request.svg";
import { ReactComponent as CostPendingIcon } from "../../Images/ci/icons/cost-pending.svg";
import { ReactComponent as DecreaseCostIcon } from "../../Images/general_icons/integration-filter-icons/dollar_decrease.svg";
import { ReactComponent as IncreaseCostIcon } from "../../Images/general_icons/integration-filter-icons/dollar_increase.svg";
import { ReactComponent as CostNoChangeIcon } from "../../Images/general_icons/integration-filter-icons/cost_nochange.svg";
import { ReactComponent as BlockedIcon } from "../../Images/ci/icons/blocked.svg";

import { CiEvents } from "../../utils/amplitudeEvents";
import { renderEllipsis } from "../../utils/formatting";
import ActionsSummary from "./ciModal/actionsSummary/actionsSummary";
import VulnerabilityLine from "../../shared/vulnerabilityLine/vulnerabilityLine";
import OwnerIndication from "../inventory/ownerIndication/ownerIndication";
import { useDispatch } from "react-redux";
import { emptyInventoryScreenFilters } from "../../consts/inventory";
import { setFilters as setInventoryFilters } from "../../redux/actions/inventoryv3Actions";
import { checkIfPolicyOrCostExists, getActionsCountsSummary, getCostSummaryPrices, getPolicySeveritiesObject } from "../../utils/workflowsHelper";
import AssetTypeRowCellV2 from "../Insights/insightsTable/assetTypeRowCellV2";

const CI_STEP_DATE_FORMAT = "MMM DD YYYY, HH:mm";

const STATUSES_ICONS = {
  planning: "ellipsis-h",
  applying: "ellipsis-h",
  "plan_success": "check",
  "plan_error": "times",
  "apply_error": "times",
  "apply_success": "check-double",
  "init_error": "times",
  "blocked": "blocked",
  "apply_no_changes": "check-double",
  "plan_no_changes": "check",
}
const fieldsMap = { createdAt: "updatedAt" };

const CiStepsTable = ({ tasks = [], statusTitles, workspaceName = '', loading = false, runsCount, pageSize = 10, setPageSize,
 setPageNumber, iacStackId, defaultParams = {}, ignoreDefaultParams, setIgnoreDefaultParams, setModalVisible, pageNumber, workspaceData = {},
  setSelectedRow, setOpenDefaultPolicyCostDrawer, setRunSort, hasGuardrails = false
}) => {
    const { t } = useTranslation("ci-pipeline");
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const { runParam = "", stepParam  = "", tabParam = ""} = defaultParams;
        if (runParam && stepParam && !ignoreDefaultParams) {
            const defaultRow = tasks.find(task => task?.runId === runParam) || {};
            handleRowClick({ row: {...defaultRow, stepParam, tabParam } });
            setIgnoreDefaultParams(true);
        }
    }, [defaultParams]);

    const handleRowClick = (params = {}, openPolicyCostDrawer = false) => {
      const rowData = params.row;
      if (isEmpty(rowData)) return;
      const runData = { ...rowData, runPage: pageNumber, hasGuardrails, repo: workspaceData?.repo };
      setSelectedRow(runData);
      setOpenDefaultPolicyCostDrawer(openPolicyCostDrawer);
      setModalVisible(true);
      const {plan = [], apply = []} = rowData || {};
      const policies = getPolicySeveritiesObject(plan, true);
      const { diffCost = 0 } = getCostSummaryPrices(plan);
      const runSummary = getActionsCountsSummary(plan, apply);
      sendEvent(CiEvents.prRowClick, { 
        workspaceRunID: rowData.runId, workspaceName, isPR: !!rowData?.pullRequestId, 
        workspaceID: workspaceData?.workspaceId, CItool: workspaceData?.runnerType,
        owner: rowData?.ownerSummary?.name, runStatus: rowData?.status, 
        branch: rowData?.branch, policyViolationsGist: policies,
        costEstimation: diffCost, runSummary
      })
    }

    const handlePolicyCostClick = (planObj, params, e, isTags) => {
      e.stopPropagation();
      const openPolicyCostDrawer = checkIfPolicyOrCostExists(planObj) || isTags;
      handleRowClick(params, openPolicyCostDrawer);
    }

    const onAssetsClick = (resourcesAddresses) => {
      const stacks = [
        {
          id: iacStackId,
          name: workspaceName,
          iacType: "terraform"
        }
      ]
      dispatch(
        setInventoryFilters({
          ...emptyInventoryScreenFilters,
          stacks,
          terraformObjectFullAddress: resourcesAddresses,
          masterType: []
        })
      );
      history.push({
        pathname: `/inventory`,
        search: `?stack=${stacks[0].id}`,
      });
    }
    
    const handleSortChange = (sorter = []) => {
      const { field, sort } = sorter[0] || {};
      setRunSort({ [fieldsMap[field] || field]: sort === "asc" ? 1 : -1});
    };

    const columns = [
      {
        headerName: t("columns.startedAt"),
        field: "createdAt",
        width: 130,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => moment(row.createdAt).format(CI_STEP_DATE_FORMAT)
      },
      {
          headerName: t("columns.title"),
          field: "title",
          flex: 2,
          sortable: true,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { row } = params;
            const { title = "", pullRequestId = "", pullRequestUrl = "" } = row || {};
            return (
              <div className={`${pullRequestId ? 'CiStepsCollapse__table-pr row g5 center': ''}`} >
                {pullRequestId && <PullRequestIcon className="CiStepsCollapse__table-pr-icon" />}
                <Tooltip title={title.length > 50 ? title : ""}>
                  <span>{renderEllipsis(title, 50)}</span>
                  {pullRequestId && (<span className="CiStepsCollapse__table-pr-title">{` (#${pullRequestId})`}</span>)}
                  </Tooltip>
                <div className="row">
                  {pullRequestUrl && (<FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => { 
                    e.stopPropagation();
                    window.open(pullRequestUrl, "_blank");
                  }}/>)}
                </div>
              </div>
            );
          },
        },
        {
          headerName:  t("columns.commitId"),
          field: "commitId",
          flex: 1,
          filterable: false,
          sortable: true,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { row } = params;
            const { commitUrl, commitId = "" } = row || {};
            const shownText = commitId ? `#${commitId.slice(0, 7)}` : ''
            return (
              <div className={`${commitUrl ? 'row g5 center': ''}`}>
                <Tooltip title={commitId}>
                  {shownText}
                </Tooltip>
                {commitUrl && (<FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => {
                  e.stopPropagation();
                  window.open(commitUrl, "_blank")} 
                }/>)}
              </div>
            );
          },
        },
        {
          headerName:  t("columns.branch"),
          field: "branch",
          width: 100,
          sortable: true,
          filterable: false,
          disableColumnMenu: true,
          valueGetter: (params) => params.row.branch
        },
        {
          headerName: t("columns.assetTypes"),
          field: "assetType",
          flex: 1,
          filterable: false,
          renderCell: (params) => {
            const { plan = [], apply = [] } = params?.row || {};
            const planSummary = plan[0];
            const applySummary = apply[0];
            const parsedDataSummary = applySummary?.parsedDataSummary?.apply || planSummary?.parsedDataSummary?.plan;
            const assetTypes = parsedDataSummary?.notNoOpResourceTypes || []
            const sortedAssetTypes = assetTypes.sort((a = "", b = "") => a?.localeCompare(b));
            return <AssetTypeRowCellV2 value={sortedAssetTypes} />
          },
        },
        {
          headerName:  t("columns.summary"),
          field: "summary",
          width: 125,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { plan = [], apply = [] } = params?.row || {};
            const planSummary = plan[0];
            const applySummary = apply[0];
            const parsedDataSummary = applySummary?.parsedDataSummary?.apply || planSummary?.parsedDataSummary?.plan;
            const isApply = !isEmpty(applySummary?.parsedDataSummary?.apply);
            return <ActionsSummary data={parsedDataSummary} liteEdition isApply={isApply} />
          }
        },
        {
          headerName:  t("columns.policyViolations"),
          field: "policyViolations",
          width: 135,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { plan = [], status } = params?.row || {};
            const shouldShowPolicyViolations = !isEmpty(plan[0]?.parsedDataSummary?.plan) && !isEmpty(plan[0]?.scanResultsSummary) && !status?.startsWith("init");
            if (!shouldShowPolicyViolations) return null
            const severities = getPolicySeveritiesObject(plan);
            return <VulnerabilityLine data={severities} isCiTable onClick={(e) => handlePolicyCostClick(plan[0], params, e)} tooltip={{title: t("columns.policyViolations")}}/>
          }
        },
        {
          headerName:  t("columns.costEstimation"),
          field: "costEstimation",
          width: 100,
          sortable: false,
          filterable: false,
          align: "center",
          disableColumnMenu: true,
          renderCell: (params) => {
            const { plan = [], status } = params?.row || {};
            const { wasPrice, nowPrice, diffPrice, diffCost, isEmptySummary } = getCostSummaryPrices(plan);
            if (isEmptySummary) return null;
            const component = diffCost > 0 ? <IncreaseCostIcon />
            : diffCost < 0 ? <DecreaseCostIcon /> 
            : <CostNoChangeIcon />;
            const showDetails = !!component;
            const isPlan = status.includes("plan") || status.includes("blocked");
            return <div className="row g5" onClick={(e) => handlePolicyCostClick(plan[0], params, e, true)}>
                      {isPlan && showDetails ? <Tooltip title={t("tooltips.costPending")}><CostPendingIcon className="CiStepsCollapse__table-cost-pending" /></Tooltip> : null}
                      <Tooltip title={showDetails ? 
                         <div className="CiStepsCollapse__table-cost-tooltip-header col g10">
                         <span className="CiStepsCollapse__table-cost-tooltip-header-title">Cost Estimation</span>
                         {diffCost === 0 ? <span className='CiStepsCollapse__table-cost-tooltip-header-content col g8'>Remains ${nowPrice}</span>
                          :<span className='CiStepsCollapse__table-cost-tooltip-header-content col g8'>
                          <span>{isPlan ? 'Current' : 'Was'} - ${wasPrice} </span>
                          <span>{isPlan ? 'After apply' : 'Now'} - ${nowPrice}</span>
                          <span>Diff - {diffPrice}$</span>
                        </span>}
                     </div> : ""}
                      overlayClassName="CiStepsCollapse__table-cost-tooltip">
                        <div className="row g5" style={!isPlan && showDetails ? { marginLeft: '21px' } : {}}>
                          {showDetails ? <span>${nowPrice}</span> : <span></span>}
                          {component}
                        </div>
                      </Tooltip>
                    </div>
          }
        },
        {
          headerName:  t("columns.tagsCoverage"),
          field: "tagsCoverage",
          width: 135,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { plan = [] } = params?.row || {};
            const planSummary = plan[0];
            const parsedDataSummary = planSummary?.parsedDataSummary?.plan || {};
            const { notNoOpTaggedResourcesCount = 0, notNoOpTaggableResourceCount = 0 } = parsedDataSummary;
            const totalTaggingPercenetage = notNoOpTaggableResourceCount ? (notNoOpTaggedResourcesCount / notNoOpTaggableResourceCount) * 100 : 100;
            const showBar = notNoOpTaggableResourceCount > 0;
            const title = showBar ? 
            (notNoOpTaggedResourcesCount === notNoOpTaggableResourceCount ? t(`tooltips.tagsCoverageFull${notNoOpTaggableResourceCount === 1 ? "Single" : ""}`, { x: notNoOpTaggableResourceCount })
            : notNoOpTaggedResourcesCount > 0 ? t("tooltips.tagsCoveragePartial", { x: notNoOpTaggableResourceCount - notNoOpTaggedResourcesCount, y: notNoOpTaggableResourceCount })
            : t(`tooltips.tagsCoverageEmpty${notNoOpTaggableResourceCount === 1 ? "Single" : ""}`, { x: notNoOpTaggableResourceCount }))
            : "";
            if (!showBar) return null;
            return (
              <Tooltip title={title}>
               <div className="CiPolicyCostSummary__tagBar table" onClick={(e) => handlePolicyCostClick(planSummary, params, e, true)}>
                  <div className="CiPolicyCostSummary__tagBar-fill" style={{ width: `${totalTaggingPercenetage}%` }}></div>
               </div>
              </Tooltip>
            )
          }
        },
        {
          headerName:  t("columns.owner"),
          field: "owner",
          width: 77,
          align: "center",
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const ownerSummary = params?.row?.ownerSummary || {};
            const { name , email = '' , initials = "", customColor } = ownerSummary;
            const userIdentity = name ? { displayName: name, email, initials: initials.toUpperCase() , customColor } : {};
            const data = { userIdentity };
            return <OwnerIndication data={data} isCiRun/>
          }
        },
        {
          headerName: t("columns.status"),
          field: "status",
          width: 170,
          sortable: true,
          resizable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { status, apply = [] } = params.row;
            const applySummary = apply[0] || {};
            const { resourcesAddresses, createdResourcesCount = 0, updatedResourcesCount = 0, importedResourcesCount = 0 } = applySummary?.parsedDataSummary?.apply || {};
            const affectedResourcesAndNotDestroyedCount = createdResourcesCount + updatedResourcesCount + importedResourcesCount;
            const isInventoryLink = ['apply_success', 'apply_error'].includes(status) && resourcesAddresses?.length > 0 && iacStackId && affectedResourcesAndNotDestroyedCount > 0;
            const ciStatusIcon = STATUSES_ICONS[status];
            return (<div className="row g8">
              {ciStatusIcon ? ciStatusIcon === STATUSES_ICONS.blocked ? <BlockedIcon className={`CiStepsCollapse__header-status-icon table ${status}-table`} /> 
               : <FontAwesomeIcon className={`CiStepsCollapse__header-status-icon table ${status}-table`} icon={["fas", ciStatusIcon]} spin={status === "planning" || status === "applying"}/> : null}
              <span className={`CiStepsCollapse__header-status-text ${status} bold`}>{statusTitles[status]}</span>
              {isInventoryLink ? 
                <div className={`CiStepsCollapse__table-assets ${status} row`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAssetsClick(resourcesAddresses);
                  }}>
                  <FontAwesomeIcon icon="layer-group" />
                  { affectedResourcesAndNotDestroyedCount }
                </div> : null}
              </div>)
          }
        },
    ];
    return (
      <div>
          <TableWrapper
            rowKey="runId"
            tableData={tasks || []}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            pageSize={pageSize}
            rowCount={runsCount}
            handlePageChange={setPageNumber}
            onPageSizeChange={setPageSize}
            onRowClick={(params) => handleRowClick(params)}
            handleSortChange={handleSortChange}
            serverSide
           />
      </div>
   )
}

export default CiStepsTable;