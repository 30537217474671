import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    changeCodifyDrawerProperty,
    fetchGeneratedCodify,
} from "../../../../redux/actions/iacImportActions";
import LongLoader from "../../../../shared/longLoader/longLoader";
import TypingDots from "../../../../shared/typingDots/typingDots";

import CreateModuleTab from "../createModuleTab/createModuleTab";
import Loading from "../../../../shared/loading/loading";
import { CODIFY_TYPES, RESOURCE_STATE_TYPES } from "../../../../consts/general";
import { ACTIVE_CODIFICATION_TYPES } from "../../codifyDrawer/codifyHelpers";
import "./createComposition.scss";

const CreateComposition = ({ selectedResources = [], handleOverweightData, OverweightDataDisplay }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); 
  const firstResource = selectedResources[0] || {};
  const isSelectedResourceDeleted = firstResource.state === RESOURCE_STATE_TYPES.deleted;
  const compositionData = useSelector((state) => state.iacImportReducer.compositionData) || [];

  useEffect(() => {
    fetchCompositionData();
  }, []);

  const fetchCompositionData = async () => {
    setLoading(true);
    const payload = {
      codeProvider: "crossplaneComposition",
      language: CODIFY_TYPES.crossplane,
      accountId: firstResource.accountId,
      integrationId: firstResource.integrationId,
      isDeleted: isSelectedResourceDeleted,
      FRNs: selectedResources.map((resource) => resource.frn),
    }
    const data = await dispatch(fetchGeneratedCodify(payload));
    if (data?.abort) return;
    await dispatch(changeCodifyDrawerProperty("compositionData", data || []));
    setLoading(false);
  };
  if (loading) {
    return (
        <div className="tab-page center">
            <LongLoader customLoader={<Loading />} duration={10000} 
            customClassName="col g10 center font-18 bold" loading={loading}
            msg1={<span className="row g8">Generating composition <TypingDots isInText/></span>}
            msg2={<span className="row g8">Hold on, it is almost ready <TypingDots isInText/></span>}/>
        </div>)
  }
  return (
    <CreateModuleTab isMultiCodify codifyType={ACTIVE_CODIFICATION_TYPES.createComposition}
    iacData={compositionData} handleOverweightData={handleOverweightData} OverweightDataDisplay={OverweightDataDisplay} />) 
};

export default CreateComposition;
