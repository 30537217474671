import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const GET_NOTIFICATIONS_AGGS = "GET_NOTIFICATIONS_AGGS";

const getAllNotifications = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const notificationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/notifications`,
      "GET"
    );
    const notifications = await notificationsReq.json();
    dispatch({
      type: GET_ALL_NOTIFICATIONS,
      payload: { notifications },
    });

    return notifications;
  });
};

const updateNotification = (notificationId, payload) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();
    const updateNotificationReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/notifications/${notificationId.toString()}`,
      "PUT", payload

    );
    return updateNotificationReq;
  });
};

const getNotificationByInsightId = (insightId) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/notifications/insight/${insightId}`,
      "GET"
    );
    const notification = await req.json();
    return notification;
  });
};

const create1Notification = (payload) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();
    const createNotificationReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/notifications`,
      "POST", payload
    );
    return createNotificationReq;
  });
};

const deleteNotification = (notificationId) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();
    const deleteNotificationReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/notifications/${notificationId.toString()}`,
      "DELETE"

    );
    return deleteNotificationReq;
  });
};

const insightSubscription = (payload) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();
    const createNotificationReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/notifications/subscribe`,
      "POST", payload
    );
    return createNotificationReq;
  });
}

const sendTestNotification = (payload) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();
    const testNotifReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/notifications/test`,
      "POST",
      payload
    );
    let data = {};
    if (testNotifReq.ok) {
      data = await testNotifReq.json();
    }
    return data;
  });
};
const getNotificationsAggs = (providers = []) => {
    return action(async(dispatch) => {
      const requestWrapper = new RequestWrapper();
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/notifications/aggs`,
        "POST",
        { 
          filters: {
            providerTypes: providers
          } 
        }
      );
      const data = await req.json() || {};
      dispatch({
        type: GET_NOTIFICATIONS_AGGS,
        payload: data
      });
      return data;
    })
}
export { getAllNotifications, updateNotification, create1Notification, deleteNotification, 
  insightSubscription, getNotificationByInsightId, sendTestNotification, getNotificationsAggs };
