import { ACTIVE_CODIFICATION_TYPES, CODIFY_LANGUAGES, MODULE_CALL_FORMAT_TYPES } from "../../components/inventory/codifyDrawer/codifyHelpers";
import { CODIFY_TYPES, IAC_TYPES } from "../../consts/general";
import {
  GET_REPO_MODULES,
  GET_MODULES_BY_REPO,
  MODULE_CALL,
  SET_PULL_REQUEST_PARAMETERS,
  CREATE_MODULE,
  SELECTED_MODULE_FILE,
  CLEAR_MODULE_DATA,
  SET_SELECTED_MODULE_CALL_FILE_PATH,
  SET_SELECTED_MODULE_CALL_FORMAT,
  CHANGE_CODIFY_DRAWER_PROPERTY,
  CLEAR_CODIFY_DRAWER_STATE
} from "../actions/iacImportActions";
import _ from 'lodash';

const initialState = {
  moduleRepos: [],
  modules: [],
  moduleData: {},
  pullReqParams: null,
  moduleFileSelected: null,
  moduleDataCreated: {},
  selectedModuleCallFilePath: "",
  selectedModuleCallFormat: MODULE_CALL_FORMAT_TYPES.HCL,
  //codify drawer state - renovation
  terraformIacData: null,
  tfCdkData: null,
  k8sIacData: null,
  otherIacData: {},
  activeIacTab: IAC_TYPES.terraform,
  activeLanguage: CODIFY_LANGUAGES[0],
  activeCodificationType: ACTIVE_CODIFICATION_TYPES.naive,
  isImportBlocksClicked: false,
  isProviderBlockClicked: false,
  importBlocks: [],
  providerBlock: [],
  isOverweightResponse: false,
  isK8sRedacted: false,
  moduleProtocol: "ssh",
  dependenciesToggle: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REPO_MODULES:
      return { ...state, moduleRepos: payload };
    case GET_MODULES_BY_REPO:
      return { ...state, modules: payload };
    case MODULE_CALL: {
      const files = payload?.Files || {};
      const defaultSelectedModuleCallCodeFilePath = state.selectedModuleCallFormat === MODULE_CALL_FORMAT_TYPES.HCL ? "main.tf" : Object.keys(files)?.[0] || "";
      return { ...state, moduleData: payload, selectedModuleCallFilePath: defaultSelectedModuleCallCodeFilePath };
    }
    case SET_PULL_REQUEST_PARAMETERS:
      return { ...state, pullReqParams: payload };
    case CREATE_MODULE:
      // set the module data and set the first object key as a selected file name
      const { redactedValues, ...files } = payload;
      return { ...state, moduleDataCreated: files, moduleFileSelected: !_.isEmpty(files) && _.isObject(files) ? Object.keys(files)[0] : null, redactedValues };
    case SELECTED_MODULE_FILE:
      return { ...state, moduleFileSelected: payload };
    case CLEAR_MODULE_DATA:
      return { ...state, moduleData: {}};
    case SET_SELECTED_MODULE_CALL_FILE_PATH:
      return { ...state, selectedModuleCallFilePath: payload };
    case SET_SELECTED_MODULE_CALL_FORMAT: 
      return { ...state, selectedModuleCallFormat: payload };
    case CHANGE_CODIFY_DRAWER_PROPERTY:
      const { key = "", value = ""} = payload;
      return { ...state, [key]: value };
    case CLEAR_CODIFY_DRAWER_STATE:
      return {...state, k8sIacData: null, terraformIacData: null, tfCdkData: null, otherIacData: {}, isOverweightResponse: false, activeIacTab: CODIFY_TYPES.terraform, activeCodificationType: ACTIVE_CODIFICATION_TYPES.naive}
    default:
      return state;
  }
};
