import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "antd";
import DropMenu from "../../../../shared/dropMenu/dropMenu";
import { ReactComponent as ReScanStacksIcon } from '../../../../Images/general_icons/re_scan_stacks.svg';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import compact from "lodash/compact";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const DiscoveryMenuActionButtons = ({ rowData, loading, handleScanAssetsClick, handleScanStacksClick, handleDeleteClick, handleEditClick, providerType="" }) => {
    const { t } = useTranslation('discovery')
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);
    const links = compact([
         handleEditClick && {
            text: t('resourcesTable.actions.edit'),
            icon: <FontAwesomeIcon icon={faPencilAlt} />,
            onClick: isViewer ? null : () => handleEditClick(rowData),
            disabled:isViewer || rowData.isDeleted
        },
        {
            text: t('resourcesTable.actions.delete'),
            icon: <FontAwesomeIcon icon="trash-alt" />,
            onClick: isViewer ? null : handleDeleteClick,
            disabled:isViewer || rowData.isDeleted
        },
    ]);

    if(providerType === '' || providerType !== 'k8s'){
        links.push(
            {
                text: t('resourcesTable.actions.scan-assets'),
                icon: <FontAwesomeIcon icon="sync-alt" />,
                onClick: isViewer ? null : () => handleScanAssetsClick(rowData?.id || rowData?._id),
                disabled:isViewer || rowData.isDeleted
            },
            {
                text:   t('resourcesTable.actions.scan-stacks'),
                icon: <ReScanStacksIcon />,
                onClick: isViewer ? null : () => handleScanStacksClick(rowData?.id || rowData?._id),
                disabled:isViewer || rowData.isDeleted
            }
        )
    }
    
    if (loading)
        return <FontAwesomeIcon
            icon="spinner"
            spin
        />
    return <Popover
        content={<DropMenu links={links} />}
        title={null}
        placement="left"
        overlayClassName="InsightMenuCell">
        <span className='integrationDiscovery__subProjectsTable__body-row-menu-icon'>
            <FontAwesomeIcon icon="ellipsis-v" />
        </span>
    </Popover>;
}

export default DiscoveryMenuActionButtons;