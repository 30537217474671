import React from "react";
import GradientBorderElement from "../../../shared/gradientBorderElement/gradientBorderElement";
import { Divider } from "antd";
import { ReactComponent as DollarImage } from "../../../Images/ci/dollar-img.svg"
import { ReactComponent as SecurityImage } from "../../../Images/ci/security-blue-img.svg"
import { ReactComponent as TaggingImage } from "../../../Images/ci/tagging-img.svg"
import { capitalizeFirst } from "../../../utils/formatting";

import "./ciPolicyCostSummary.scss";

const CiPolicyCostSummary = ({ data = {}, onClick }) => {
    const { scanResultsSummary = {}, costResultsSummary = {}, parsedDataSummary = {} } = data;
    const beforeCost = costResultsSummary.cost?.before?.totalMonthlyCost || 0;
    const afterCost = costResultsSummary.cost?.after?.totalMonthlyCost || 0;
    const was = beforeCost == 0 ? beforeCost : parseFloat(beforeCost).toFixed(2);
    const now = afterCost == 0 ? afterCost : parseFloat(afterCost).toFixed(2);

    const { notNoOpTaggedResourcesCount = 0, notNoOpTaggableResourceCount = 0 } = parsedDataSummary;
    const totalTaggingPercenetage = notNoOpTaggableResourceCount ? (notNoOpTaggedResourcesCount / notNoOpTaggableResourceCount) * 100 : 100;
    const showTaggingText = notNoOpTaggableResourceCount === 0 ? "No taggable resources" : "";
    
    const isCostRemains = was === now;
    const severities = scanResultsSummary.rego?.notNoOpResourcesViolationsSeverityCount || {};
    const severitiesArr = [];
    // sort
    ["HIGH", "MEDIUM", "LOW"].forEach((key) => {
        const severity = severities[key] || 0;
        severitiesArr.push({ key: key.toLowerCase(), severityCount: severity });
    });

    const onClickSummary = () => {
        const summaryData = { policySummary: severities, costSummary: { was, now }, tagsSummary: { totalTaggingPercenetage, a: notNoOpTaggedResourcesCount, b: notNoOpTaggableResourceCount } };
        onClick(summaryData);
    };
    
    return (
    <GradientBorderElement customContainerStyle={isCostRemains ? { maxHeight: '340px' } : {}} content={
        <div className="CiPolicyCostSummary col" onClick={onClickSummary}>
            <SecurityImage className="CiPolicyCostSummary__securityImg" />
            <DollarImage className="CiPolicyCostSummary__dollarImg" />
            <TaggingImage className="CiPolicyCostSummary__taggingImg" />
            <div className="col g8">
                <span className="CiPolicyCostSummary__titleCont-text">Tags Coverage</span>
                {showTaggingText ? <span className="font-14 bold purple-text">{showTaggingText}</span>
                 : <div className="col g5">
                    <span className="font-14 bold purple-text">{+totalTaggingPercenetage.toFixed(1)}%</span>
                    <div className={`CiPolicyCostSummary__tagBar`}>
                        <div className="CiPolicyCostSummary__tagBar-fill" style={{ width: `${totalTaggingPercenetage}%` }}></div>
                    </div>
                </div>}
            </div>
            <Divider />   
            <div className="CiPolicyCostSummary__severity col g5">
            <div className="CiPolicyCostSummary__titleCont row">
                <span className="CiPolicyCostSummary__titleCont-text">Policy Violations</span>
            </div>
                {severitiesArr.map((severity = {}) => {
                    const { key, severityCount } = severity;
                    return (
                        <div className="row between" key={key}>
                            <span className="CiPolicyCostSummary__severity-text">{capitalizeFirst(key)}</span>
                            <span className={`CiPolicyCostSummary__severity-count ${key}`}>{severityCount}</span>
                        </div>
                    )
                })}
            </div>
            <Divider />
            <div className="CiPolicyCostSummary__titleCont row">
                <span className="CiPolicyCostSummary__titleCont-text">Cost Estimation</span>
            </div>
            {!isCostRemains && <div className="row g8">
                <span className="CiPolicyCostSummary__cost">Was</span>
                <div className="row"> 
                    <span className="CiPolicyCostSummary__cost was">${was}</span>
                    <span className="CiPolicyCostSummary__cost-perMonth">/m</span>
                </div>
            </div>}
            <div className="col">
                <span className="CiPolicyCostSummary__cost purple">{isCostRemains ? 'Remains': 'Now'}</span>
                <div className="row">
                    <span className="CiPolicyCostSummary__cost purple now">${now}</span>
                    <span className="CiPolicyCostSummary__cost-perMonth purple">/m</span>
                </div>
            </div>
        </div>}/>);
};

export default CiPolicyCostSummary;