import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Drawer, Tooltip } from "antd";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  formatAwsStringWithUnderscore,
  formatNoUnderscore,
} from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import ActionBtn from "../../../shared/actionBtn/actionBtn";
import AppBtn from "../../../shared/appBtn/appBtn";
import CodeDiff from "../../../shared/codeDiff/codeDiff";
import IssueModal from "../../inventory/issueModal/issueModal";
import { getInventoryAmpliData } from "../../inventory/inventoryTable/inventoryHelper";
import DriftExclusionModal from "./driftExclusionModal/driftExclusionModal";
import FixDrift from "./fixDrift/fixDrift";

import { sendEvent } from "../../../utils/amplitude";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import "./driftAnalyzeDrawer.scss";

const SHOW_MORE_FROM_INDEX = 2;

const DriftAnalyzeDrawer = ({
  visible,
  data,
  closeDrawer,
  noJiraIntegrations,
}) => {
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const excludedDrifts = useSelector((state) => state.driftsExclusionsReducer.rules);
  const { t } = useTranslation("inventory");
  const [code, setCode] = useState("");
  const [json, setJson] = useState("");
  const [jsonMode, setJsonMode] = useState(false);
  const [exclusionVisible, setExclusionVisible] = useState(false);
  const [properties, setProperties] = useState([]);
  const [excludedProperties, setExcludedProperties] = useState([]);
  const [excludedPropertiesPrefixes, setExcludedPropertiesPrefixes] = useState([]);
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const [showMoreDriftDetails, setShowMoreDriftDetails] = useState(false);
  const [pullRequestResponse, setPullRequestResponse] = useState({});


  const isShowMoreBar = code.length > SHOW_MORE_FROM_INDEX;
  useEffect(() => {
    return () => setShowMoreDriftDetails(false)
  }, []);

  useEffect(() => {
    if (data?.state !== 'modified') return;
    setProperties([]);
    setExcludedProperties([]);
    setCode("");
    if (visible && !_.isEmpty(data)) {
      formatCodeKeys(data?.drift);
      const jsonCode = !_.isEmpty(data?.drift)
        ? JSON.stringify(data?.drift, null, 2)
        : "";
      setJson(jsonCode);
    }
  }, [visible, data]);

  // create new obj cuz there are different keys => inventoryValue / k8sValue || awsValue
  const formatCodeKeys = (arr) => {
    if (!_.isEmpty(arr)) {
      const output = _.map(arr, (item) => {
        const availableIacKeys = [
          "stateValue",
          "helmValue",
          "tfValue",
          "iacValue",
        ];
        const availableProviderKeys = [
          "inventoryValue",
          "k8sValue",
          "awsValue",
          "providerValue",
        ];

        const iacKey = availableIacKeys.filter((key) => _.has(item, key));
        const providerKey = availableProviderKeys.filter((key) => _.has(item, key));
        return {
          inventory: item?.[providerKey],
          keyName: item?.keyName,
          state: item?.[iacKey],
        };
      });

      return setCode(output);
    }
    return;
  };

  useEffect(() => {
    if (!_.isEmpty(code)) {
      let codeKeys = [];
      let excludedProperties = [];
      let excludedPropertiesPrefixes = [];
      let globalScope = `${data?.provider}objects`;

      // enabled excluded drifts by asset type or global type
      const enabledExcludedDriftsByType = _.filter(
        excludedDrifts,
        (driftObj) =>
          driftObj?.isEnabled === true &&
          (driftObj?.type === data?.assetType || driftObj?.type === globalScope)
      );

      // excluded drifts by provider id
      const excludedDriftsByProvider = _.filter(
        enabledExcludedDriftsByType,
        (driftObj) => {
          if (!_.isEmpty(driftObj?.filters?.integrations)) {
            if (
              !_.isUndefined(
                driftObj?.filters?.integrations?.find(
                  (providerId) => providerId === data?.providerId
                )
              )
            ) {
              return driftObj;
            }
          } else {
            if (_.isEmpty(driftObj?.filters?.arn)) {
              return driftObj;
            }
          }
        }
      );

      // excluded drifts by arn
      const excludedDriftsByArn = _.filter(
        enabledExcludedDriftsByType,
        (driftObj) =>
          !_.isUndefined(
            driftObj?.filters?.arn?.find((singleArn) => singleArn === data?.arn)
          )
      );

      const matchingExcludedDrifts = _.concat(
        excludedDriftsByProvider || [],
        excludedDriftsByArn || []
      );

      _.map(matchingExcludedDrifts, (driftExclusion) => {
        excludedPropertiesPrefixes.push(driftExclusion?.prefixProperties);
      });

      setExcludedPropertiesPrefixes(_.flatten(excludedPropertiesPrefixes));

      _.map(code, (element) => {
        codeKeys.push(element?.keyName);
        if (!_.isEmpty(matchingExcludedDrifts)) {
          // excluded drifts by property
          const excludedDriftsByProperty = _.filter(
            matchingExcludedDrifts,
            (driftObj) =>
              !_.isUndefined(
                driftObj?.properties?.find((prop) => prop === element?.keyName)
              )
          );
          // if exclusion found and the property is not already on the list
          if (
            !_.isEmpty(excludedDriftsByProperty) &&
            _.isUndefined(
              excludedProperties.find((exprop) => exprop === element?.keyName)
            )
          ) {
            excludedProperties.push(element?.keyName);
          }
        }
      });
      setExcludedProperties(excludedProperties);
      setProperties(codeKeys);
    }
  }, [code]);

  const renderListOrText = (listStr) => {
    if (!_.isEmpty(listStr)) {
      if (listStr?.startsWith("map[")) {
        let stringToPrint = listStr.slice(4, -1);
        return stringToPrint;
      }
      if(listStr === "[]"){
        return listStr;
      }
      if (listStr?.startsWith("[")) {
        let listArr = listStr.slice(1, -1).split(",");
        let formattedArr = _.map(listArr, (arn) => arn.slice(1, -1));
        let sortedArray = _.sortBy(formattedArr);
        let stringToPrint = "";
        _.map(sortedArray, (arn) => (stringToPrint += `${arn}\n`));
        return stringToPrint;
      }
      return listStr;
    }
    return "";
  };

  const getStateString = (state) => {
    let returnString = "";
    if (state.startsWith("{") || state.startsWith("[{")) {
      try {
        returnString = JSON.stringify(JSON.parse(state), null, 2);
        return returnString;
      } catch (error) {
        returnString = state;
        return returnString;
      }
    }
    return renderListOrText(state);
  };

  const isPropertyExcluded = (keyName) => {
    if (excludedProperties?.find((property) => property === keyName)) {
      return true;
    }
    if (excludedPropertiesPrefixes?.find((prefix) => keyName?.toString()?.startsWith(prefix))) {
      return true;
    }
    return false;
  }

  const codeWithExclusions = useMemo(() => _.map(code, item => ({...item, excluded: isPropertyExcluded(item?.keyName)})) , [code, excludedProperties, excludedPropertiesPrefixes]);
  const handleOpenExcludeClick = () => {
    setExclusionVisible(true);
    sendEvent(inventoryEvents.clickedExcludeDriftButton, { ...getInventoryAmpliData(data), accessedVia: 'exclude drift button' })
  };

  const renderCode = () => {
    if (!_.isEmpty(code)) {

      // sort the array so excluded will be at end of list
      return _.map(_.orderBy(codeWithExclusions, 'excluded', ['asc']) || [], (element = {}, index) => {
        const { inventory = "", state = "", keyName: property = "", excluded }  = element;
        const beforeString = getStateString(inventory?.toString());
        const afterString = getStateString(state?.toString());
        if (!showMoreDriftDetails && index >= SHOW_MORE_FROM_INDEX) return null;
        return (
          <div className={`DriftAnalyzeDrawer__wrapper-body-diff-row ${excluded ? 'excluded' : ''}`} key={uuidv4()}>
            {excluded && (
            <Tooltip placement="top" title="Excluded Drift">
               <span className="DriftAnalyzeDrawer__wrapper-body-item-flag center">
                 <FontAwesomeIcon icon={faEyeSlash} />
                </span>
            </Tooltip>)}
            <span className="DriftAnalyzeDrawer__wrapper-body-diff-row-title">{formatNoUnderscore(property)}</span>
            <CodeDiff oldValue={beforeString} newValue={afterString} />
         </div>
        );
      });
    }
    return (
      <div className="tab-page center">
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  };

  const handleCloseModal = () => {
    setExclusionVisible(false);
  };
  
  const handleShowMoreLess = () => {
    if (!showMoreDriftDetails) {
      sendEvent(inventoryEvents.clickedShowMoreDriftDetails, { frn: data?.frn })
    }
    setShowMoreDriftDetails(!showMoreDriftDetails);
  }
  
  const isPullRequestResponseSuccess = pullRequestResponse?.ok && pullRequestResponse?.data;

  return (
    <div>
      <Drawer
        title={null}
        placement="right"
        closable={false}
        onClose={closeDrawer}
        visible={visible}
        key="right"
        className="DriftAnalyzeDrawer"
        width="80vw"
        maskStyle={{ backgroundColor: "rgba(0,0,0,.5)" }}
        destroyOnClose
        zIndex={1001}
      >
        <div className="DriftAnalyzeDrawer__wrapper col">
          <div className="DriftAnalyzeDrawer__wrapper-title">
            <span className="wb-text font-18 bold">
              {data?.name}
            </span>
            <div className="row g5">
              <div className="row g5">
                <span className="bold">Type:</span>
                {formatAwsStringWithUnderscore(data?.assetType || "")}
              </div>
              <span>|</span>
              <div className="row g5">
                <span className="bold">ID:</span>
                {data?.assetId || ""}
              </div>
            </div>
          </div> 
          <FontAwesomeIcon
            icon={faTimes}
            onClick={closeDrawer}
            className="DriftAnalyzeDrawer__wrapper__close"
          />
          <div className="DriftAnalyzeDrawer__wrapper-body col g10">
            <div className="row between">
              <div className="row g8">
                <FontAwesomeIcon icon="code-branch" className="purple-text font-16" />
                <span className="font-16 bold">Drift details</span>
              </div>
                <div className="row g10">
                    <ActionBtn text="" action="json" icon="json" onClick={() => {
                      setJsonMode(!jsonMode)
                      sendEvent(inventoryEvents.clickedDriftDetailsViewButton, { frn: data?.frn })
                      }} purple/>
                    <ActionBtn text="" icon="copy" action="copy" stringToAction={json} purple onClickDifferentActions={() => sendEvent(inventoryEvents.copiedDriftDetails, { frn: data?.frn })} refresh={visible}/>
                    <ActionBtn text="" action="download" fileType="json" fileName="drift" icon="donwload" stringToAction={json} purple refresh={visible} onClickDifferentActions={() => sendEvent(inventoryEvents.downloadedDriftDetails, { frn: data?.frn })}/>               
               </div>
              </div>
            {jsonMode  ? (
              <div className="DriftAnalyzeDrawer__wrapper-body-diff-code json">
                <SyntaxHighlighter
                  style={atomDark}
                  wrapLines={true}
                  showLineNumbers={true}
                  language="hcl"
                  lineProps={{
                    style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                  }}
                >
                  {json}
                </SyntaxHighlighter>
              </div>
            ) : (
              <div className="DriftAnalyzeDrawer__wrapper-body-diff">
                <div className="DriftAnalyzeDrawer__wrapper-body-diff-header">
                    <span className="DriftAnalyzeDrawer__wrapper-body-diff-title">Property</span>
                    <span className="DriftAnalyzeDrawer__wrapper-body-diff-title">Running Configuration</span>
                    <span className="DriftAnalyzeDrawer__wrapper-body-diff-title">{"Desired Configuration (IaC)"}</span>
                </div>
                <div className="col">
                  <div className={`DriftAnalyzeDrawer__wrapper-body-diff-code ${isShowMoreBar ? "withShowMore" : ""}`}>
                    {renderCode()}
                  </div>
                  {isShowMoreBar && (
                  <div className="DriftAnalyzeDrawer__wrapper-body-diff-showMoreLess row g8">
                    <FontAwesomeIcon icon={showMoreDriftDetails ? "minus" : "plus"} className="DriftAnalyzeDrawer__wrapper-body-diff-showMoreLess-icon" onClick={handleShowMoreLess}/>
                    <span className="DriftAnalyzeDrawer__wrapper-body-diff-showMoreLess-text" onClick={handleShowMoreLess}>{showMoreDriftDetails ? "Show less..." : "Show more..."}</span>
                  </div>)}
                </div>
              </div>
            )}
            {!_.isEmpty(codeWithExclusions) && <FixDrift assetData={data} pullRequestResponse={pullRequestResponse} setPullRequestResponse={setPullRequestResponse} driftsWithExcluded={codeWithExclusions} />}
          </div>
          <div className="DriftAnalyzeDrawer__wrapper-footer row">
            <AppBtn
              text="Issue"
              onClick={() => setIssueModalOpen(true)}
              icon={<FontAwesomeIcon icon={["fab", "jira"]} />}
              disabled={_.isEmpty(data) || isViewer}
              style={{ transform: "translateY(-1px)" }}
            />
            <AppBtn
              text="Exclude Drift"
              onClick={handleOpenExcludeClick}
              disabled={
                isViewer || excludedProperties?.length === properties?.length
              }
              icon={<FontAwesomeIcon icon={faEyeSlash} />}
            />
          </div>
        </div>
      </Drawer>
      <DriftExclusionModal
        visible={exclusionVisible}
        data={data}
        properties={properties}
        handleCloseModal={handleCloseModal}
        isPropertyExcluded={isPropertyExcluded}
      />
      <IssueModal
        visible={issueModalOpen}
        handleClose={() => {
          setIssueModalOpen(false);
        }}
        selectedResources={[data]}
        noJiraIntegrations={noJiraIntegrations}
        driftDrawer
        code={code}
        isPropertyExcluded={isPropertyExcluded}
      />
    </div>
  );
};

export default DriftAnalyzeDrawer;
