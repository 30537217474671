import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import PostPlanTable from '../postPlanTable';
import PostPlanGraph from '../postPlanGraph/postPlanGraph';
import ActionsSummary from '../actionsSummary/actionsSummary';
import { useDispatch, useSelector } from 'react-redux';
import { getInitLogData, getWorkflowResources } from '../../../../redux/actions/ciWorkflowsActions';
import AppEmpty from '../../../../shared/appEmpty/appEmpty';
import DiagnosticArr from '../postApply/diagnostic/diagnosticArr';
import { getProperFilters } from '../../../../utils/workflowsHelper';
import { CI_STEPS, CI_STEPS_MODES } from '../../../../consts/ci-workflows';
import LogSyntaxHighlighter from '../logSyntaxHighlighter/logSyntaxHighlighter';
import Loading from '../../../../shared/loading/loading';
import { checkIfIsJson } from '../../../../utils/helpers';
import { appToast } from '../../../../shared/appToast/appToast';

const MAX_GRAPH_LENGTH = 100;

const PostPlan = ({ planId, parsedDataSummary = {}, handleOpenConfig, workspaceData, taskId, isLogModeSelected = false, tCi = {}, openRemediationByDefault = false, onEmptyListReturned }) => {
    const { notNoOpResourcesCount: planResourcesCount = 0, hasDiagnosticResources = false, diagnostics: globalDiagnostics = [] } = parsedDataSummary;
    const defaultMode = planResourcesCount > MAX_GRAPH_LENGTH ? CI_STEPS_MODES.table : CI_STEPS_MODES.graph;
    
    const [dataByMode, setDataByMode] = useState({});
    const [loadingByMode, setLoadingByMode] = useState({});
    const [loadingLog, setLoadingLog] = useState(false);
    const [planPageNumber, setPlanPageNumber] = useState(1);
    const [planPageSize, setPlanPageSize] = useState(5);
    const [planTableSort, setPlanTableSort] = useState({ name: 1 });

    const [actionFilters, setActionFilters] = useState({});
    const [severityFilters, setSeverityFilters] = useState({});

    const planLogs = useSelector((state) => state.ciWorkflowsReducer.logs.plan) || "";

    const dispatch = useDispatch();

    useEffect(() => {
        fetchRunData(defaultMode); // usually graph
    }, []);
    
    useEffect(() => {
        if (isLogModeSelected && isEmpty(planLogs)) {
            fetchLogData();
        }
    }, [isLogModeSelected]);

    useEffect(() => {
        if (dataByMode?.table) {
            fetchRunData(CI_STEPS_MODES.table);
        }
    }, [planPageSize, planPageNumber, planTableSort]);

    useEffect(() => {
        if (dataByMode?.graph && !dataByMode?.table) { // once graph is fetched, fetch table
            fetchRunData(CI_STEPS_MODES.table);
        }
    }, [dataByMode]);

    useEffect(() => {
        if (dataByMode?.graph && dataByMode?.table) {
            fetchRunData(CI_STEPS_MODES.table)
        }
    }, [actionFilters, severityFilters]);

    const renderGlobalDiagnostics = useCallback(() => (
        <div className="col g10">
            <span className="bold">Global diagnostics</span>
            <DiagnosticArr diagnostics={globalDiagnostics} taskId={taskId} openRemediationByDefault={openRemediationByDefault} />
        </div>
    ), [globalDiagnostics, openRemediationByDefault]);
    
    const fetchLogData = async () => {
        setLoadingLog(true);
        const res = await dispatch(getInitLogData(taskId, CI_STEPS.plan, "raw")) || "";
        if (res && checkIfIsJson(res)) {
            appToast("warning", "", tCi("postPlan.warningJson"))
        }
        setLoadingLog(false);
    };
    const fetchRunData = async (mode) => {
        if (planResourcesCount == 0 && !hasDiagnosticResources){
            return onEmptyListReturned();
        }
        setLoadingByMode({ ...loadingByMode, [mode]: true });
        const options = { mode, filters: getProperFilters(actionFilters, severityFilters) };
        if (mode === CI_STEPS_MODES.table) {
            const newOffset = (planPageNumber - 1) * planPageSize;
            options.offset = newOffset > planResourcesCount ? 0 : newOffset;
            options.size = planPageSize;
            options.sort = planTableSort;
        }
        const response = await dispatch(getWorkflowResources(planId, options));
        const list = response?.resources || [];
        if (response) {
            setLoadingByMode({ ...loadingByMode, [mode]: false });
            return setDataByMode({ ...dataByMode, [mode]: list });
        }
        setLoadingByMode({ ...loadingByMode, [mode]: false });
    };

    const renderLogMode = () => {
        if (loadingLog) return <div className="PostPlanGraph items-1"><Loading /></div>;
        if (!planLogs) return <AppEmpty customStyle="ciResourcesNotFound" text={tCi("postPlan.logsNotFound")}/>;
        return <LogSyntaxHighlighter log={planLogs} customClassName="PostPlanGraph items-1" />
    }

    const noGraphOrTableData = (!loadingByMode.graph && !loadingByMode.table && isEmpty(dataByMode?.graph?.nodes) && isEmpty(dataByMode?.table));
    if (!isLogModeSelected && ((planResourcesCount == 0 && !hasDiagnosticResources) || noGraphOrTableData)) {
        if (globalDiagnostics?.length > 0) return renderGlobalDiagnostics();
        return (
          <div className="PostPlanChart center empty">
            <AppEmpty customStyle="ciResourcesNotFound" text="The current Terraform plan does not contain any infrastructure changes" />
          </div>
        );
    }

    return (
        <div className="PostPlan col g10">
           {!isLogModeSelected && <ActionsSummary data={parsedDataSummary} actionFilters={actionFilters} />}
           {defaultMode === CI_STEPS_MODES.graph && !isLogModeSelected ? (
           
           <PostPlanGraph data={dataByMode?.graph} loading={loadingByMode?.graph} 
           handleOpenConfig={handleOpenConfig} workspaceData={workspaceData}
            actionFilters={actionFilters} setActionFilters={setActionFilters}
            severityFilters={severityFilters} setSeverityFilters={setSeverityFilters}/>)
          : isLogModeSelected ? renderLogMode() : null}
           
           <PostPlanTable data={dataByMode?.table} loading={loadingByMode?.table || loadingByMode?.graph} 
             parsedDataSummary={parsedDataSummary} pageSize={planPageSize} setPageSize={setPlanPageSize} taskId={taskId}
             setPageNumber={setPlanPageNumber} handleOpenConfig={handleOpenConfig} hasDiagnosticResources={hasDiagnosticResources}
             workspaceData={workspaceData} setPlanTableSort={setPlanTableSort} openRemediationByDefault={openRemediationByDefault} />

           {globalDiagnostics.length ? renderGlobalDiagnostics() : null} 
        </div>
    );
};
export default PostPlan;