import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import "./tagsTable.scss";

const TagsTable = ({ tags = {} }) => { 
    const tagsArray = useMemo(() => Object.entries(tags).map(([key, value]) => ({ key, value})), [tags]);
    return (
        <div className="TagsTable">
            <div className="TagsTable__header g5">
                <span className="TagsTable__header-title">Key</span>
                <span className="TagsTable__header-title">Value</span>
            </div>
            <div className="TagsTable__body">
                <div className="TagsTable__body-divider"/>
                {tagsArray.map((tag = {}) => {
                    const { key, value } = tag;
                    return (
                        <div className="TagsTable__body-row g5" key={uuidv4()}>
                            <span className="TagsTable__body-row-title">{key}</span>
                            <span className="TagsTable__body-row-value">{value}</span>
                        </div>
                    )
                })}
            </div>
    </div>
    )
} 

export default TagsTable;