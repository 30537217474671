import React from "react";
import { useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import map from "lodash/map";
import keys from "lodash/keys";
import groupBy from "lodash/groupBy";
import flatten from "lodash/flatten";
import {
  formatIacStatus,
  providerNameFormatter,
} from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModulesTab from "./modulesTab/modulesTab";
import CreateModuleTab from "./createModuleTab/createModuleTab";

// components
import Loading from "../../../shared/loading/loading";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import { providers } from "../../../utils/providers";
import LongLoader from "../../../shared/longLoader/longLoader";
import { useTranslation } from "react-i18next";
import {CODIFY_TYPES, IAC_TYPES, PROVIDERS} from "../../../consts/general";

import { areAllRegionsSame } from "../../../utils/helpers";

import cloudMigration from "../../../Images/clouds/cloud_migration.svg"

import { ACTIVE_CODIFICATION_TYPES, configureAdvancedArr, configureBasicArr, configureCloudMigrationArr } from "../codifyDrawer/codifyHelpers";

import "./smartCodifyTab.scss";
import CreateComposition from "./createComposition/createComposition";

const UNSUPPORT_MENU_CODIFY_TYPES = [CODIFY_TYPES.helm];
const MAX_STRING_LENGTH_TO_RENDER = 50000;
const OverweightDataDisplay = <AppEmpty text={
    <div>
      <span className="opac">The generated code exceeds the display limit</span>
      <div className="centered"><span className="opac">Click the </span>Download<span className="opac"> button to review it.</span></div>
    </div>}
customStyle="custom" />

const SmartCodifyTab = ({
  codifyType,
  isFetchingIaC,
  iacData,
  activeType = "naive",
  handleSetActiveTab,
  selectedResources,
  hideNonNaiveTypes,
  isCodifyAI = false,
  isImportBlocksClicked,
  handleOverweightData = () => null,
  codifySessionId,
}) => {
  const { t } = useTranslation("inventory", { keyPrefix: "codifyDrawer" });
  const isTfCdkCodifyType = codifyType === CODIFY_TYPES.tfcdk; //temporary until we will support tfcdk all tabs
  const activeTab = useSelector((state) => state.iacImportReducer.activeIacTab);
  const isCrossplaneActiveTab = activeTab === CODIFY_TYPES.crossplane;
  const isIacDataExists = !isEmpty(iacData) && !isEmpty(iacData[0]);
  const providerType = selectedResources[0]?.provider;
  const isK8sProviderType = providerType === IAC_TYPES.k8s;
  const isSameRegions = areAllRegionsSame(selectedResources);
  const isRevisionOrDeletedCodify = !!selectedResources[0].revisionId || selectedResources[0].state === "deleted";
  const isCodifyTypeSupportMenu = !UNSUPPORT_MENU_CODIFY_TYPES.includes(codifyType);
  const shouldShowAdvancedMenu = !hideNonNaiveTypes || isCrossplaneActiveTab;

  const prefix = `Created using ${providerNameFormatter(providerType)} Provider Version ${providers[providerType]?.providerVersion}\n# `;
  const descriptions = {
    naive: isK8sProviderType ? `#${prefix}This mode shows the Terraform code for the chosen resource in its most straightforward format.\n\n` : "",
    smart: isK8sProviderType ? `#${prefix}This mode is the same as Basic but with dependencies connected with a data source \n# instead of an explicit resource id where applicable.\n\n` : "",
    dependencies: isK8sProviderType ? `${prefix}This mode codifies the chosen resource with all of its unmanaged dependencies (that exist in the inventory). \n# Codified dependencies will be connected using a data source.\n\n`: "",
    fullDependencies: isK8sProviderType ? `${prefix}This mode is excellent for building modules or reusable code and codifies all dependencies (that exist in the inventory), \n# even if some are already codified.\n\n`: "",
    modules: prefix.replace("#", ""),
    resourceGroup: "",
  };

  const basicArr = configureBasicArr(isCodifyAI, isRevisionOrDeletedCodify, t, isCrossplaneActiveTab, isCrossplaneActiveTab);
  const advancedArr = configureAdvancedArr(isRevisionOrDeletedCodify, providerType, isTfCdkCodifyType, t, isCrossplaneActiveTab);
  const cloudMigrationArr = configureCloudMigrationArr(isRevisionOrDeletedCodify, providerType);

  const importBlocksComment = `\n# Terraform v 1.5.0, has made importing resources into Terraform much simpler.\n# Rather than using the terraform import command, you can now simply add the\n# block to your Terraform code and actually import resources during an apply.\n\n`;

  const canMigrate = !isEmpty(selectedResources) && includes([PROVIDERS.aws, PROVIDERS.gcp, PROVIDERS.azurerm], selectedResources[0]?.provider);

  const renderMenu = (arr) => {
    return map(arr, (item = {}) => {
      const { unvisible, disabled, value, icon, selectedIcon, text, isBeta, hide } = item;
      if (unvisible) return;
      
      const isNotNaive = value !== ACTIVE_CODIFICATION_TYPES.naive;
      const disabledSmart = (isEmpty(selectedResources) && isNotNaive) || disabled;
      const shouldRender = (!hideNonNaiveTypes || !isNotNaive || isCrossplaneActiveTab) && !([ACTIVE_CODIFICATION_TYPES.modules, ACTIVE_CODIFICATION_TYPES.createModule].includes(value) && codifyType === CODIFY_TYPES.pulumi) && !hide;
      const isValueActive = activeType === value;

      if (shouldRender) {
        return (
          <div key={uuidv4()} className={`SmartCodifyTab__menu-item row ${isValueActive ? "active" : ""} ${disabledSmart ? "disabled" : ""}`} 
          onClick={() => {
            !disabledSmart ? handleSetActiveTab(value) : null
             }}>
            <span className="row g10">
              {icon && <img className="SmartCodifyTab__menu-item-icon" src={isValueActive ? selectedIcon || icon : icon} alt={text}/>}
                <span className="row g10">
                  {text}
                  {isBeta && <span className="purple-flag-outline">{t("menu.preview")}</span>}
                </span>
            </span>
          </div>
        );
      }
    });
  };

  const renderAppEmpty = (text) => (
    <div className="full-page center">
      <AppEmpty text={text} customStyle="code" />
    </div>
  );
  const renderOverweightDataDisplay = () => {
    return (
      <div className="full-page center">
        {OverweightDataDisplay}
      </div>
    )
  };

  const renderCode = () => {
    if (!isSameRegions && codifyType === CODIFY_TYPES.cloudformation) {
      return renderAppEmpty(t("cloudFormationRegionsError")); ;
    }
    if (isFetchingIaC) {
      return (
        <div className="full-page center bold g10 SmartCodifyTab__loading">
          <LongLoader msg1={t("loading.msg1")} msg2={t("loading.msg2")} loading={isFetchingIaC} customLoader={<Loading />} duration={15000} />
        </div>
      );
    }
    if (!isFetchingIaC && !isIacDataExists) {
      return renderAppEmpty(t("noData"));
    }

    switch (activeType) {
      case ACTIVE_CODIFICATION_TYPES.naive:
        const isMultipleFilesCodify = isArray(iacData) && iacData.length > 1;
        if (isCodifyAI) {
          return isMultipleFilesCodify ? (
            <CreateModuleTab codifyType={activeType} iacData={iacData} isMultiCodify handleOverweightData={handleOverweightData} />
          ) : (
            renderSyntaxHighlight(iacData[0]?.content)
          );
        }
        const hasHclAsString = isArray(iacData) && !isEmpty(iacData[0]?.HclAsString);
        const code = `${descriptions[activeType]}${hasHclAsString ? getAllCode() : iacData}`;
        return renderSyntaxHighlight(code);
      case ACTIVE_CODIFICATION_TYPES.smart:
        return renderSources();
      case ACTIVE_CODIFICATION_TYPES.dependencies:
        return renderWrappers();
      case ACTIVE_CODIFICATION_TYPES.fullDependencies:
        return renderWrappers();
      case ACTIVE_CODIFICATION_TYPES.modules:
        return (
          <ModulesTab
            description={descriptions?.modules}
            prefix={isK8sProviderType ? prefix : ""}
            selectedResources={selectedResources}
            isImportBlocksClicked={isImportBlocksClicked}
            importBlocksComment={importBlocksComment}
            handleOverweightData={handleOverweightData}
            OverweightDataDisplay={OverweightDataDisplay}
            codifySessionId={codifySessionId}
          />
        );
      case ACTIVE_CODIFICATION_TYPES.createComposition:
        return <CreateComposition selectedResources={selectedResources} handleOverweightData={handleOverweightData} OverweightDataDisplay={OverweightDataDisplay}/>;
      case ACTIVE_CODIFICATION_TYPES.createModule:
      case ACTIVE_CODIFICATION_TYPES.createModuleDependencies:
        return <CreateModuleTab codifyType={activeType} selectedResources={selectedResources} handleOverweightData={handleOverweightData} OverweightDataDisplay={OverweightDataDisplay}/>;
      case ACTIVE_CODIFICATION_TYPES.resourceGroup:
        return renderWrappers();
      case ACTIVE_CODIFICATION_TYPES.awsMigration:
      case ACTIVE_CODIFICATION_TYPES.azureMigration:
      case ACTIVE_CODIFICATION_TYPES.googleMigration:
        return renderSyntaxHighlight(iacData[0]?.content);
      default:
        return renderAppEmpty(t("noData"));
    }
  };

  const getAllCode = () => {
    const hclArray = map(iacData, (source) => source?.HclAsString);
    return `${isImportBlocksClicked ? importBlocksComment : ""}${flatten(hclArray).join("")}`;
  };

  const getResourceName = (code) => {
    if (isString(code)) {
      let words = code?.split(" ");
      let name = words[2];
      name = name?.replaceAll(`"`, "");
      return name;
    }
    return "";
  };

  const renderSyntaxHighlight = (code = '') => {
    if (code?.length > MAX_STRING_LENGTH_TO_RENDER){
      handleOverweightData(true);
      return renderOverweightDataDisplay();
    }
    if (!isEmpty(code) && !isArray(code)) {
      return (
        <SyntaxHighlighter
          style={atomDark}
          showLineNumbers={true}
          language="hcl"
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
        >
          {code}
        </SyntaxHighlighter>
      );
    }
    return renderAppEmpty(t("noData"));
  };

  const renderWrappers = () => {
    if (isIacDataExists) {
      const assetTypeGroups = groupBy(iacData, "AssetType");
      const description = `${descriptions[activeType]}`;
      const code = map(keys(assetTypeGroups), (assetType) => {
        let typeTitle = `# ${assetType}\n`;
        const inner = map(assetTypeGroups[assetType], (source) => {
          let name = `# ${getResourceName(source?.HclAsString)} [${formatIacStatus(source?.State)}]\n\n`;
          let innerCode = source?.HclAsString;
          const output = typeTitle + name + innerCode;
          return output;
        });
        const x = inner.join("");
        return x;
      });
      let join = `${isImportBlocksClicked ? importBlocksComment : ""}${code.join("")};`;
      return renderSyntaxHighlight(description + join);
    }
  };

  const renderSources = () => {
    if (isIacDataExists) {
      const description = `${descriptions[activeType]}`;
      const output = map(iacData, (source) => {
        let codeBlock = `# ${source?.AssetType} \n# ${getResourceName(source?.HclAsString)} [${formatIacStatus(source?.State)}] \n\n${source?.HclAsString}`;
        return codeBlock;
      });
      const joined = `${isImportBlocksClicked ? importBlocksComment : ""}${output.join("")}`;
      return renderSyntaxHighlight(description + joined);
    }
    return;
  };
  return (
    <div className={`SmartCodifyTab ${isCodifyTypeSupportMenu ? "withMenu" : ""} `}>
      {isCodifyTypeSupportMenu && (
        <div className="SmartCodifyTab__menu">
          <div className="SmartCodifyTab__menu-seperator row">
            <FontAwesomeIcon icon="code" />
            {t("menu.basic")}
          </div>
          {renderMenu(basicArr)}
          {shouldShowAdvancedMenu && (
            <div className="SmartCodifyTab__menu-seperator row">
              <FontAwesomeIcon icon="sitemap" />
              {t("menu.advanced")}
            </div>
          )}
          {renderMenu(advancedArr)}
          {canMigrate && codifyType === CODIFY_TYPES.terraform && (
              <>
                <div className="SmartCodifyTab__menu-seperator row">
                  <img className="CodifyDrawer__tab-icon" src={cloudMigration} alt="Cloud Migration" />
                  {t("menu.cloudMigration")}
                </div>
                {renderMenu(cloudMigrationArr)}
              </>
          )}
        </div>
      )}
      <div className="SmartCodifyTab__code">{renderCode()}</div>
    </div>
  );
};

export default SmartCodifyTab;
