import { GUARDRAILS_TYPES, formatCostAmount } from "../../../guardrails/guardrailsModal/guardrailsHelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as CreateDeleteIcon } from "../../../../Images/ci/icons/createdelete.svg";
import { ReactComponent as DeleteCreateIcon } from "../../../../Images/ci/icons/deletecreate.svg";
import { ReactComponent as DecreaseCostIcon } from "../../../../Images/general_icons/integration-filter-icons/dollar_decrease.svg";
import { ReactComponent as IncreaseCostIcon } from "../../../../Images/general_icons/integration-filter-icons/dollar_increase.svg";
import { WORKFLOWS_ACTIONS_MAP } from "../../../../consts/ci-workflows";
import AssetTypeImage from "../../../../shared/assetTypeImage/assetTypeImage";
import { capitalizeFirst, formatAwsStringWithUnderscore } from "../../../../utils/formatting";

const actionsIcons = {
    create: <FontAwesomeIcon className="PostPlanTable__action-icon create" icon="plus-circle" />,
    delete: <FontAwesomeIcon className="PostPlanTable__action-icon delete" icon="minus-circle" />,
    update: <FontAwesomeIcon className="PostPlanTable__action-icon update" icon="pen" />,
    read: <FontAwesomeIcon className="PostPlanTable__action-icon read" icon="check-double" />,
    "no-op": <FontAwesomeIcon className="PostPlanTable__action-icon no-op" icon="ban" />,
    import: <FontAwesomeIcon className="PostPlanTable__action-icon import" icon="download" />,
    createdelete: <CreateDeleteIcon className="PostPlanTable__action-icon-createdelete" />,
    deletecreate: <DeleteCreateIcon className="PostPlanTable__action-icon-deletecreate" />,
}

const getGuardrailsTableColumnsByType = (type, tColumns) => {
    const disabledFields = {
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
    }
    switch (type) {
        case GUARDRAILS_TYPES.resource:
            return [
                {
                    headerName: tColumns("action"),
                    field: "action",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => {
                        const action = params.row?.assetInfo?.action?.[0] || params.row?.criteria?.resource?.action || "";
                        return <div className="row g8">
                            {actionsIcons[action]}
                            <span className="GuardrailsBlockTable__item-table-actionName">{WORKFLOWS_ACTIONS_MAP[action]}</span>
                        </div>
                    }
                },
                {
                    headerName: tColumns("resourceAddress"),
                    field: "resourceAddress",
                    flex: 1, 
                    ...disabledFields,
                    renderCell: (params) => params.row?.assetInfo?.address || params.row?.criteria?.resource?.address || ""
                },
                {
                    headerName: tColumns("region"),
                    field: "region",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => params.row?.assetInfo?.region || ""
                },
                {
                    headerName: tColumns("assetType"),
                    field: "assetType",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => {
                        const type = params.row?.assetInfo?.type || params.row?.criteria?.resource?.assetType || "";
                        return (
                           <div className="row g8">
                            <AssetTypeImage assetType={type} customStyle={{ height: "16px", width: "16px", borderRadius: "4px"}}/>
                            <span>{formatAwsStringWithUnderscore(type)}</span>
                           </div>
                        );
                      },
                }
            ]
            case GUARDRAILS_TYPES.policy: 
            return [
                {
                    headerName: tColumns("violatedPolicy"),
                    field: "violatedPolicy",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => params.row?.violationPolicyInfo?.name || ""

                },
                {
                    headerName: tColumns("resourceAddress"),
                    field: "resourceAddress",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => params.row?.assetInfo?.address ||  params.row?.violationPolicyInfo?.address || ""
                },
                {
                    headerName: tColumns("expectedValue"),
                    field: "expectedValue",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => params.row?.violationPolicyInfo?.expectedValue || ""
                },
                {
                    headerName: tColumns("policySeverity"),
                    field: "policySeverity",
                    flex: 1,
                    ...disabledFields,
                    renderCell: (params) => {
                        const severity = params.row?.violationPolicyInfo?.severity || "";
                        const highestLower = severity.toLowerCase();
                        return  <span className={`Diagnostic__severityBtn ${highestLower} GuardrailsBlockTable__item-table-severity`}>
                            {capitalizeFirst(severity)}
                        </span>
                    }
                }
            ]
            case GUARDRAILS_TYPES.cost:
                return [
                    {
                        headerName: tColumns("costLimit"),
                        field: "costLimit",
                        flex: 1,
                        ...disabledFields,
                        renderCell: (params) => {
                            const thresholdAmount = params.row?.criteria?.cost?.thresholdAmount;
                            const thresholdPercentage = params.row?.criteria?.cost?.thresholdPercentage;
                            const costLimit = thresholdAmount ? formatCostAmount(thresholdAmount)
                            : thresholdPercentage ? `${thresholdPercentage}%` : "";
                            return <span>{costLimit}</span>
                        }
                    },
                    {
                        headerName: tColumns("expectedCostChange"),
                        field: "expectedCostChange",
                        flex: 1,
                        ...disabledFields,
                        renderCell: (params) => {
                            const thresholdAmount = params.row?.criteria?.cost?.thresholdAmount;
                            const thresholdPercentage = params.row?.criteria?.cost?.thresholdPercentage;
                            const change = params?.row?.assetInfo?.expectedCostChange || "";
                            const expectedCostChange = parseFloat(change).toFixed(2);
                            const costChange = thresholdAmount ? formatCostAmount(expectedCostChange) : thresholdPercentage ? `${expectedCostChange}%` : "";
                            const component = expectedCostChange > 0 ? <IncreaseCostIcon />
                            : expectedCostChange < 0 ? <DecreaseCostIcon /> : null;
                            return <div className="row g5">
                                <span>{costChange}</span>
                                {component}
                            </div>
                        }
                    }
                ]
            case GUARDRAILS_TYPES.tag:
                return [
                    {
                        headerName: tColumns("resourceAddress"),
                        field: "resourceAddress",
                        flex: 1,
                        ...disabledFields,
                        renderCell: (params) => params.row?.assetInfo?.address || ""
                    },
                    {
                        headerName: tColumns("hasTags"),
                        field: "hasTags",
                        width: 93,
                        align: "center",
                        ...disabledFields,
                        renderCell: (params) => {
                            const hasTags = params?.row?.criteria?.tag?.hasTags || false;
                            return <FontAwesomeIcon className={`GuardrailsBlockTable__item-table-icon ${hasTags ? "passed" : ""}`} icon={hasTags ? "check" : "times"}  />
                        }
                    },
                    {
                        headerName: tColumns("missingTag"),
                        field: "missingTag",
                        flex: 1,
                        ...disabledFields,
                        renderCell: (params) => {
                            const missingTag = params?.row?.criteria?.tag?.missingTags || [];
                            const missingTagString = missingTag.join(", ");
                            return <span>{missingTagString}</span>
                        }
                    }
                ]
            default: return [];
    }
}
const getStatusesCount = (status = []) => {
    let passed = 0;
    if (!status.length) return {};
    status.forEach((item) => {
        if (item.isPassed) {
            passed++;
        }
    });
    return { passedCount: passed, failedCount: status.length - passed };
}
export {
    getGuardrailsTableColumnsByType,
    getStatusesCount,
}