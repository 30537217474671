import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createModule,
  setModuleFile,
} from "../../../../redux/actions/iacImportActions";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import map from "lodash/map";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import { iacProviders } from '../../../../utils/icons';
import { keys } from "lodash";
import LongLoader from "../../../../shared/longLoader/longLoader";
import TypingDots from "../../../../shared/typingDots/typingDots";
import {ACTIVE_CODIFICATION_TYPES} from "../../codifyDrawer/codifyHelpers";
import "./createModuleTab.scss";

const MAX_STRING_LENGTH_TO_RENDER = 50000;
const CreateModuleTab = ({ selectedResources, codifyType, isMultiCodify, iacData, handleOverweightData, OverweightDataDisplay }) => {
  const dispatch = useDispatch();
  const [loadingCreate, setLoadinCreate] = useState(false);

  const moduleFileSelected = useSelector(
    (state) => state.iacImportReducer.moduleFileSelected
  );
  const moduleDataCreated = useSelector(
    (state) => state.iacImportReducer.moduleDataCreated
  );

  useEffect(() => {
    if (!isMultiCodify) {
      return handleCreateModule();
    }
    initMultiCodify();
  }, [codifyType]);

  useEffect(() => {
    if (moduleFileSelected) {
      const syntaxToRender = isMultiCodify ? iacData?.find((iacData) => iacData.filePath === moduleFileSelected)?.content
      : isString(moduleDataCreated[moduleFileSelected]) ? moduleDataCreated[moduleFileSelected] : ''
  
      if(syntaxToRender?.length > MAX_STRING_LENGTH_TO_RENDER) {
        handleOverweightData(true)
      }
      else {
        handleOverweightData(false)
      }
    }

  }, [moduleFileSelected]);

  const initMultiCodify = async () => {
    setLoadinCreate(true);
    await dispatch(setModuleFile(iacData[0]?.filePath));
    setLoadinCreate(false);
  };

  const handleCreateModule = async () => {
    setLoadinCreate(true);

    let assetType = "";
    let assetId = "";
    let provider = "";
    let providerId = "";
    let resources = [];

    for (let i = 0; i < selectedResources?.length; i++) {
      const resource = selectedResources[i];

      if (i === 0) {
        assetType = resource.assetType;
        assetId = resource.assetId;
        provider = resource.provider;
        providerId = resource.integrationId;
      } else {
        resources.push({
          terraformAssetType: resource.assetType,
          providerId: resource.integrationId,
          assetId: resource.assetId,
        });
      }
    }

    const payload = {
      assetType,
      assetId,
      providerId,
      provider,
      resources,
      codificationMode: codifyType === ACTIVE_CODIFICATION_TYPES.createModule ? "CREATE_MODULE_PORTKEY" : "CREATE_MODULE_PORTKEY_WITH_DEPENDENCIES",
    };

    const res = await dispatch(createModule(payload));
    if (res?.abort) return; 
    setLoadinCreate(false);
  };

  const handleRenderFileList = () => {
    if (isEmpty(moduleDataCreated) && isEmpty(iacData)) {
      return null;
    }
    // if error msg returned
    if (keys(moduleDataCreated)?.includes("msg")) {
      return null;
    }
    const arrayToMap = isMultiCodify ? iacData : moduleDataCreated;
    return map(arrayToMap, (val, valKey) => {
      const key = isMultiCodify ? val?.filePath : valKey;
      const fileName = key?.split("/").pop();
      return (
          <span
              key={key}
              className={`CreateModuleTab__menu-item ${isMultiCodify ? '' : 'row'} ${
                  moduleFileSelected === key ? "active" : null
              }`}
              onClick={() => dispatch(setModuleFile(key))}
          >
        {!isMultiCodify && <img src={iacProviders('terraform')} alt="terraform" />}
            {fileName}
      </span>
      );
    });
  };

  const renderCode = () => {
    if(isEmpty(moduleDataCreated[moduleFileSelected]) && isEmpty(iacData)) {
      return <AppEmpty text="No data" customStyle="code" />
    }
    const syntaxToRender = isMultiCodify ? iacData.find((iacData) => iacData.filePath === moduleFileSelected)?.content
    : isString(moduleDataCreated[moduleFileSelected]) ? moduleDataCreated[moduleFileSelected] : ''

    if(syntaxToRender?.length > MAX_STRING_LENGTH_TO_RENDER) {
      return <div className="tab-page center">{OverweightDataDisplay}</div>
    }

    return (
      <SyntaxHighlighter
          style={atomDark}
          showLineNumbers={true}
          language="hcl"
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
        >
          {syntaxToRender}
        </SyntaxHighlighter>
    )
  }

  if (loadingCreate) {
    return (
      <div className="tab-page center">
        <LongLoader customLoader={<Loading />} duration={10000} 
          customClassName="col g10 center font-18 bold" loading={loadingCreate}
          msg1={<span className="row g8">Generating Terraform Module <TypingDots isInText/></span>}
          msg2={<span className="row g8">Hold on, it is almost ready <TypingDots isInText/></span>}/>
      </div>
    );
  }

  if (!loadingCreate && isEmpty(moduleDataCreated) && isEmpty(iacData)) {
    return (
      <div className="tab-page center">
        <AppEmpty text="No Module data" customStyle="code" />
      </div>
    );
  }

   // if error msg returned
   if (!loadingCreate && keys(moduleDataCreated)?.includes("msg") && isEmpty(iacData) ) {
    return (
      <div className="tab-page center">
        <AppEmpty text={moduleDataCreated?.msg} customStyle="code" />
      </div>
    )
  }

  return (
    <div className="CreateModuleTab">
      <div className="CreateModuleTab__menu col">{handleRenderFileList()}</div>
      <div className="CreateModuleTab__code">
      {renderCode()}
      </div>
    </div>
  );
};

export default CreateModuleTab;
