import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import animationDataDark from "./paiDarkLottie.json";
import animationDataLight from "./paiLightLottie.json"
import "./lottieLoadingPai.scss";
import { useTranslation } from "react-i18next";

const LottieLoadingPai = () => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const { t } = useTranslation("lottie");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: themeDark ? animationDataDark : animationDataLight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      className: "LottieLoadingPai-animation",
    },
  };

  return (
    <div className="col">
      <div className={`LottieLoadingPai ${themeDark ? "dark" : "light"}`}>
        <Lottie
          options={defaultOptions}
          isStopped={false}
          isPaused={false}
          height={200}
          width={200}
        />
      </div>
      <div>
        <p className="LottieLoadingPai__text text center font-14">
          {t("scanning-in-progress")}
        </p>
      </div>
    </div>
  );
};

export default LottieLoadingPai;