import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_API_KEY, AMPLITUDE_API_KEY_STAG } from "../consts/config";
import { getSubdomain } from "../utils/helpers";

const FIREFLY_DEV1_URL = "app-env1.dev.firefly.ai";
const FIREFLY_DEV2_URL = "app-env2.dev.firefly.ai";
const FIREFLY_DEV3_URL = "app-env3.dev.firefly.ai";

export const initAmplitude = (
  userId,
  userEmail,
  userName,
  accountId,
  accountName
) => {
  const subDomain = getSubdomain();
  const isLocal = subDomain?.includes("localhost");
  const isFireflySession = userEmail?.includes("gofirefly") || userEmail?.includes("firefly") || userEmail?.includes("infralight");
  const isDevSession = [FIREFLY_DEV1_URL, FIREFLY_DEV2_URL, FIREFLY_DEV3_URL].includes(window.location.host);
  const isV2 = subDomain === "v2";

  if (isLocal || (isFireflySession && !isDevSession) || isV2) {
    return;
  }
  const amplitudeApiKey = isDevSession ? AMPLITUDE_API_KEY_STAG : AMPLITUDE_API_KEY;
  amplitude.init(amplitudeApiKey, userId, { defaultTracking: false });
  const amplitudeIdentify = new amplitude.Identify()
  amplitudeIdentify.set('userEmail', userEmail);
  amplitudeIdentify.set('userName', userName);
  amplitudeIdentify.set('accountId', accountId);
  amplitudeIdentify.set('accountName', accountName);

  amplitude.identify(amplitudeIdentify);
  // if (!isDevSession) { 
  //   const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });
  //   amplitude.add(sessionReplayTracking);
  // }
};

// eventType must match value from the amplitudeEvents.js file
// eventProperties most be an object!
export const sendEvent = (eventType = "", eventProperties = {}) => {
  const subDomain = getSubdomain();
  if (subDomain === "v2" || subDomain?.includes("localhost")) {
    return;
  }
  return amplitude.logEvent(eventType, eventProperties);
};
