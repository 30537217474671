import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";
import ComplianceLineChart from "../../../shared/complianceLineChart/complianceLineChart";

const ComplianceCell = ({ row = {} }) => {

  const showExclusion = useSelector(
    (state) => state.insightsV2Reducer.filters?.showExclusion
  );

  const { excluded_assets, total_assets, total_assets_by_types = 0 } = row;
  const count = showExclusion ? excluded_assets : total_assets;

  if (row?.isScanning) {
    return (
      <span className="row g8">
        Scanning
        <FontAwesomeIcon icon="spinner" className="purple-text" spin />
      </span>
    );
  }

  if (!row?.isEnabled) {
    return null;
  }

  if (!count && row?.isEnabled) {
    return (
      <ComplianceLineChart affected={0} total={total_assets_by_types}  />
    );
  }
  
  return (
    <ComplianceLineChart affected={count} total={total_assets_by_types}  />
  );
};

export default ComplianceCell;