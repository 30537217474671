import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { CI_STEPS } from "../../../../consts/ci-workflows";
import { getDiagnosticRemediationById, getInitLogData } from "../../../../redux/actions/ciWorkflowsActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../shared/loading/loading";
import LogSyntaxHighlighter from "../logSyntaxHighlighter/logSyntaxHighlighter";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import RemediationBlock from "../remediationBlock/remediationBlock";
import { appToast } from "../../../../shared/appToast/appToast";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

const InitStep = ({ taskId, tCi, isInitFailed }) => {
    const { user } = useAuth0();
    const initLog = useSelector((state) => state.ciWorkflowsReducer.logs?.init) || "";
    const [loading, setLoading] = useState(false);
    const [isRemediateOpen, setIsRemediateOpen] = useState(false);
    const [remedationLoading, setRemedationLoading] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "diagnosticRemediation" })

    const diagnosticsRemediationById = useSelector((state) => state.ciWorkflowsReducer.diagnosticsRemediationById) || {};
    const diagnosticRemediation = diagnosticsRemediationById[taskId] || {};
    const isDiagnosticRemediation = !isEmpty(diagnosticRemediation);

    useEffect(() => {
        if (initLog) return;
        fetchInitData();
    }, []);

    useEffect(() => {
        if (initLog && isInitFailed) {
            fetchRemediationData();
        }
    },[initLog]);

    const fetchInitData = async () => {
        setLoading(true);
        await dispatch(getInitLogData(taskId, CI_STEPS.init, "raw"))
        setLoading(false);
    };

    const fetchRemediationData = async () => {
        if (isDiagnosticRemediation) return;
        setRemedationLoading(true);
        const payload = { diagnosticId: taskId, diagnosticType: "init failure", diagnosticDetails: initLog, taskId, userEmail: user.email }; 
        const resOk = await dispatch(getDiagnosticRemediationById(payload));
        if (!resOk) {
            appToast("error", t("error.title"), t("error.subtitle"));
            setIsRemediateOpen(false);
        }
        setRemedationLoading(false);
    };
    if (loading) return <div className="basic-loading"><Loading /></div>;
    if (!initLog) return <AppEmpty customStyle="ciResourcesNotFound" text={tCi("initStep.notFound")}/>;
    return (
        <div className="col g10">
            {isInitFailed && <RemediationBlock isDiagnosticRemediation={isDiagnosticRemediation} data={diagnosticRemediation} remediationLoading={remedationLoading} setIsRemediateOpen={setIsRemediateOpen} />}
            <LogSyntaxHighlighter log={initLog} />
        </div>
    )
};

export default InitStep;