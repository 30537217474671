import React, { useEffect, useState, useMemo } from "react";
import groupBy from "lodash/groupBy";
import { getGuardrailsViolationsByTaskId } from "../../../../redux/actions/workflowsGuardrailsActions";
import Loading from "../../../../shared/loading/loading";
import { ReactComponent as TagIcon } from '../../../../Images/ci/guardrails/tag.svg';
import { ReactComponent as CostIcon } from '../../../../Images/ci/guardrails/cost.svg';
import { ReactComponent as PolicyIcon } from '../../../../Images/ci/guardrails/policy.svg';
import { ReactComponent as ResourceIcon } from '../../../../Images/ci/guardrails/resources.svg';
import { useDispatch } from "react-redux";

import { Tooltip } from "antd";
import { capitalizeFirst } from "../../../../utils/formatting";
import GuardrailsBlockTable from "./guradrailsBlockTable";

import { getStatusesCount } from "./guardrailsTableHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./guardrailBlock.scss";

const TYPE_ICONS = {
    cost: CostIcon,
    policy: PolicyIcon,
    resource: ResourceIcon,
    tag: TagIcon,
}
const GuardrailBlock = ({ taskId, handleOpenConfig }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [statusFilter, setStatusFilter] = useState({});
    
    const dispatch = useDispatch();

    const isNewDesign = data?.newDesign || false;
    const status = isNewDesign ? data?.status : [];
    const filterredStatus = (!statusFilter.passed && !statusFilter.failed) || (statusFilter.passed && statusFilter.failed) ? status
    : status.filter((item) => statusFilter.passed ? item.isPassed : !item.isPassed);
    const statusesByType = useMemo(() => groupBy(filterredStatus, "ruleType"), [data, filterredStatus, isNewDesign]);
    const { passedCount = 0, failedCount = 0 } = getStatusesCount(status);
    

    useEffect(() => {
        fetchGuardrailsViolations()
    }, []);
    
    const fetchGuardrailsViolations = async () => {
        setLoading(true);
        const response = await dispatch(getGuardrailsViolationsByTaskId(taskId));
        setData(response);
        setLoading(false);
    };

    if (loading){
        return <div className="basic-loading"><Loading /></div>
    }
    const handleStatusClick = (e, field) => {
        e.stopPropagation();
        const toggle = !statusFilter[field];
        setStatusFilter({...statusFilter,  [field]: toggle });
    }
    return (
        <div className="GuardrailBlock basic-100 col g15">
          {isNewDesign ? 
          <>
            <div className="GuardrailBlock-header row g10">
                {failedCount > 0 && <div className={`GuardrailBlock-status ${statusFilter.failed ? "selected" : ""} row g5`} onClick={(e) => handleStatusClick(e, "failed")}>
                    <FontAwesomeIcon icon="times" className="GuardrailBlock-icon-status failed"/>
                    <span className="GuardrailBlock-title unbold">{failedCount} Failed</span>
                </div>}
                {passedCount > 0 && failedCount > 0 && <div className="GuardrailBlock-divider horizontal"/>}
                {passedCount > 0 && <div className={`GuardrailBlock-status ${statusFilter.passed ? "selected" : ""} row g5`} onClick={(e) => handleStatusClick(e, "passed")}>
                    <FontAwesomeIcon icon="check" className="GuardrailBlock-icon-status passed"/>
                    <span className="GuardrailBlock-title unbold">{passedCount} Passed</span>
                </div>
                }
            </div>
            <div className="GuardrailBlock-divider"/>
            {Object.entries(statusesByType).map(([ruleType, ruleArray = []]) => {
                const Icon = TYPE_ICONS[ruleType];
                return (
                <div className="col g10" key={ruleType}>
                    <div className="row g10">
                        {Icon && <Icon className="GuardrailBlock-icon pointer"/>}
                        <span className="unbold">{capitalizeFirst(ruleType)}</span>
                    </div>
                    {ruleArray.map((item = {}, index) => (<GuardrailsBlockTable ind={index} key={item.ruleId} data={item} type={ruleType} taskId={taskId} handleOpenConfig={handleOpenConfig}/>))}
                </div>)
            })}
          </>
          : data.violations?.map((item = {}) => {
            const { ruleId, description = "", ruleType = "", ruleName = ""} = item;
            const Icon = TYPE_ICONS[ruleType];
            return (
                <div key={ruleId} className="GuardrailBlockItem">
                        <div className="Diagnostic__header guardrail row g8">
                        {Icon && <Tooltip title={capitalizeFirst(ruleType)}><Icon className="Diagnostic__icon-guardrail pointer"/></Tooltip>}
                            <div className="row g5">
                                <span className="Diagnostic__header-title unbold">{ruleName}</span>
                            </div>
                        </div>
                        <div className="Diagnostic__body col g5">
                            <span className="Diagnostic__text guardrails">{description}</span>
                        </div>
                </div>
            )
          })}
        </div>
    );
};

export default GuardrailBlock;