import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useRef,useEffect } from "react";
import ModalVideo from 'react-modal-video';
import { PopupWidget } from "react-calendly";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FIREFLY_BOOK_DEMO_URL } from "../../consts/links";

import tvIcon from "../../Images/general_icons/welcome-block-users/tv.svg";
import bookIcon from "../../Images/general_icons/welcome-block-users/book.svg";
import chatBot from "../../Images/general_icons/welcome-block-users/chat-bot.svg";
import welcomeBlockUserIcon from "../../Images/general_icons/welcome-block-users/welcome_block_user.svg";
import CookiesManager from "../../utils/cookiesManager";
import "./welcomeBlockUsers.scss";

const videoId = "Q3ft49cSlY4";

const WelcomeBlockUsers = ({ text, height }) => {
    const { logout } = useAuth0();
    const cookieMgr = new CookiesManager();
    const [isOpen, setOpen] = useState(false);
    const calendlyRef = useRef(null);

    const addSerenityScript = () => {
        const script = document.createElement('script');
        script.src = "https://chatling.ai/js/embed.js";
        script.async = true;
        script.setAttribute("data-id","3938641417");
        script.setAttribute("id","chatling-embed-script");
        document.head.append(script);
    }

    useEffect(() => {
        addSerenityScript();
    }, [])

    const loginLink = () => {
        cookieMgr.setAccessToken(null, 0);
        logout({ returnTo: window.location.origin });
    }

    const watchVideo = () => {
        setOpen(true)
    }

    const scheduleDemo = () => {
        calendlyRef.current.onClick();
    }

    const onClickChatBot = () => {
        const chatBot = document.getElementById("chatling-open-chat-icon")
        if(chatBot?.children?.length > 0){
            chatBot.children[0].click()
        }
    }

    const generateDefaultBlockUserText = () => {
        return (
            <>
                <div className="welcome-block-user-card__text-container">
                    <div className="welcome-block-user-card__description-container">
                        <div className="welcome-block-user-card__description-container__sub-title">
                            <b>Hello there!</b>
                        </div>
                        <div>
                            Thank you for your interest in Firefly.
                        </div>
                        <div>
                            Currently, our platform is tailored for corporate and business users.
                        </div>
                        <div>
                            For the best experience, <b>please sign-up using your company email address.</b>
                        </div>
                        </div>
                    <div className="welcome-block-user-card__links-container">
                        <div className="sub-header-text">
                            Want to learn more?
                        </div>
                        <div className="welcome-block-user-card__link-container">
                            <div>
                                <img src={tvIcon} />
                            </div>
                            <div className="welcome-block-user-card__text-link" onClick={watchVideo}>
                                Watch 1-min video
                            </div>
                        </div>
                        <div className="welcome-block-user-card__link-container">
                            <div>
                                <img src={bookIcon} />
                            </div>
                            <div className="welcome-block-user-card__text-link" onClick={scheduleDemo}>
                                Book a personal demo with a cloud expert
                            </div>
                        </div>
                        <div className="welcome-block-user-card__link-container">
                            <div>
                                <img src={chatBot} />
                            </div>
                            <div className="welcome-block-user-card__text-link" onClick={onClickChatBot}>
                                Ask Serenity anything about Firefly
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const generateBlockUserText = () => {
        return text ?? generateDefaultBlockUserText()
    }

    return (
        <div className="welcome-block-user-login-container">
            <div className="welcome-block-user-headers">
                <div className="welcome-block-user-headers__slogan-title">
                    <span className="welcome-block-user-headers__slogan-title-main">Automatically turn any cloud into IaC</span>
                    <span className="welcome-block-user-headers__slogan-title-sub">Future-Proof Your Infrastructure</span>
                </div>
            </div>
            <div className="welcome-block-user-card" style={{height: height || 511}}>
                <button className="welcome-block-user-login-container__close" onClick={loginLink}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <img className="welcome-block-user-card__image-style" src={welcomeBlockUserIcon} />
                {generateBlockUserText()}
                <div className="welcome-block-user-card__login-link welcome-block-user-card__text-link" onClick={loginLink}>
                    Back to the login screen
                </div>
            </div>
            <PopupWidget
                ref={calendlyRef}
                className="calendly-button"
                url={FIREFLY_BOOK_DEMO_URL}
                rootElement={document.getElementById('root')}
            />
             <ModalVideo
				channel="youtube"
				youtube={{ mute: 0, autoplay: 0 }}
				isOpen={isOpen}
				videoId={videoId}
				onClose={() => setOpen(false)} 
			/>
        </div>
    );};

export default WelcomeBlockUsers;
