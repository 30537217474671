import React, { useMemo } from "react";
import ReactFlow, {
  Controls,
  Background,
} from "react-flow-renderer";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import DiagramResourceBlock from "./diagramResourceBlock/diagramResourceBlock";
import { sendEvent } from "../../../../utils/amplitude";
import { CiEvents } from "../../../../utils/amplitudeEvents";
import { WORKFLOWS_ACTIONS_MAP } from "../../../../consts/ci-workflows";

import "./postPlanChart.scss";

const getProviderFromCiType = (ciType = '') => ciType.split("_")[0];

const PostPlanChart = ({ data = {}, handleOpenConfig, workspaceData, actionFilters, setActionFilters, severityFilters, setSeverityFilters}) => {
  const { height,  width, nodes = [], edges = [], actionLegend = {}, severityLegend = {} } = data;

  const actionArr = Object.keys(actionLegend) || [];
  const severityArr = Object.keys(severityLegend) || [];
  const severityLegendExists = severityArr.some((key) => severityLegend[key]?.visible);
  
  const onResourceClick = (data) => {
    const horizontalTabAvailability = compact(["resource plan", data?.isCostResults ? "cost analysis" : "", data?.scanResults?.length ? "policy violations" : ""]);
    sendEvent(CiEvents.ciRunResourceClick, { from_graph: true, actionType: data?.action, assetType: data?.type, workspaceID: workspaceData?.workspaceId, workspaceRunID: workspaceData?.runId, horizontalTabAvailability });
    handleOpenConfig(data);
  }

  const filteredNodes = useMemo(() => nodes.filter((node) => {
    let filttered = true;
    if (!isEmpty(actionFilters) && !actionFilters[node.data?.action]) {
      filttered = false;
    }
    if (!isEmpty(severityFilters) && !severityFilters[node.data?.highestSeverity?.toLowerCase()]) {
      filttered = false;
    }
    return filttered;
  }), [nodes, actionFilters, severityFilters]);

  const nodeTypes = useMemo(() => ({
    ASSET: (node = {}) => (
        <DiagramResourceBlock
          resource={{
            data: {
              provider: getProviderFromCiType(node.data?.type) || "",
              assetType: node.data?.type,
              ...node.data,
            }
          }}
          onClick={() => onResourceClick(node.data)}
        />
  )}));
  
  const handleSetActionFilters = (key) => {
    const toggle = !actionFilters[key];
    const newActionsFilters = { ...actionFilters, [key]: toggle };
    if (!toggle) {
      delete newActionsFilters[key];
    }
    setActionFilters(newActionsFilters);
  };
  const handleSetSeverityFilters = (key) => {
    const toggle = !severityFilters[key];
    const newSeverityFilters = { ...severityFilters, [key]: toggle };
    if (!toggle) {
      delete newSeverityFilters[key];
    }
    setSeverityFilters(newSeverityFilters);
  };
  return (
    <>
      <div
        style={{
          minHeight: "100%",
          minWidth: "100%",
          height: height || "100%",
          width: width || "100%",
        }}>
        <ReactFlow
          defaultNodes={filteredNodes}
          defaultEdges={edges}
          nodeTypes={nodeTypes}
          className="PostPlanChart-container"
          attributionPosition="bottom-right"
          minZoom={0.1}
          defaultZoom={0.5}
          defaultPosition={[(height / 2),( width/2 )]}
          fitView
          fitViewOptions={{ includeHiddenNodes: true, maxZoom: 0.8 }}
        >
          <Controls showInteractive={false} />
          <Background />
            <div className="PostPlanChart__legend row g8">
              <span className="PostPlanChart__legend-title">Action</span>
              {actionArr.map((key) => actionLegend[key]?.visible && (
                <div className={`PostPlanChart__legend-item pointer row g8 ${actionFilters[key] ? 'selected' : ''}`} key={key} onClick={() => handleSetActionFilters(key)}>
                  <div className={`PostPlanChart__legend-item-color ${key}`} />
                    <span className="PostPlanChart__legend-item-text">{WORKFLOWS_ACTIONS_MAP[key?.toLowerCase()] || actionLegend[key]?.label}</span>
                </div>
              ))}
            </div>
            {severityLegendExists &&
                <div className="PostPlanChart__legend sever row g8">
                <span className="PostPlanChart__legend-title">Severity</span>
              {severityArr.map((key) => severityLegend[key]?.visible && (
                <div className={`PostPlanChart__legend-item pointer row g8 ${severityFilters[key] ? 'selected' : ''}`} key={key} onClick={() => handleSetSeverityFilters(key)}>
                  <div className={`PostPlanChart__legend-item-color ${key}`} />
                  <span className="PostPlanChart__legend-item-text">{severityLegend[key]?.label}</span>
                </div>
              ))}
            </div> }
        </ReactFlow>
      </div>

    </>
  );
};

export default PostPlanChart;
