import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import _ from "lodash";

export const GET_AWS_INTEGRATIONS = "GET_AWS_INTEGRATIONS";
export const CREATE_AWS_INTEGRATION = "CREATE_AWS_INTEGRATION";
export const GET_AWS_INTEGRATION_FETCHING_STATUS =
  "GET_AWS_INTEGRATION_FETCHING_STATUS";
export const DELETE_AWS_INTEGRATION = "DELETE_AWS_INTEGRATION";
export const GET_ALL_BUCKETS = "GET_ALL_BUCKETS";
export const GET_ALL_S3_OBJECTS = "GET_ALL_S3_OBJECTS";
export const GET_TFC_WORKSPACES = "GET_TFC_WORKSPACES";
export const GET_WEBHOOK_INTEGRATIONS = "GET_WEBHOOK_INTEGRATIONS";

export const GET_SLACK_INTEGRATIONS = "GET_SLACK_INTEGRATIONS";
export const GET_SLACK_CHANNELS = "GET_SLACK_CHANNELS";

export const GET_TEAMS_INTEGRATIONS = "GET_TEAMS_INTEGRATIONS";
export const GET_OPSGENIE_INTEGRATIONS = "GET_OPSGENIE_INTEGRATIONS";
export const GET_GOOGLE_CHAT_INTEGRATIONS = "GET_GOOGLE_CHAT_INTEGRATIONS";

export const GET_WEBEX_INTEGRATIONS = "GET_WEBEX_INTEGRATIONS";
export const GET_WEBEX_ROOMS = "GET_WEBEX_ROOMS";

export const SET_AWS_INTEGS = "SET_AWS_INTEGS";
export const SET_AWS_INTEGS_PERCENTAGE = "SET_AWS_INTEGS_PERCENTAGE";
export const GET_ACCOUNT_EXTERNAL_ID = "GET_ACCOUNT_EXTERNAL_ID";
export const GET_ON_PREM_IP = "GET_ON_PREM_IP";
export const GET_TORQ_INTEGRATIONS = "GET_TORQ_INTEGRATIONS";
export const GET_PAGERDUTY_INTEGRATIONS = "GET_PAGERDUTY_INTEGRATIONS";
export const GET_PAGERDUTY_SERVICES = "GET_PAGERDUTY_SERVICES";
export const GET_ALL_INTEGRATION_COUNTS = "GET_ALL_INTEGRATION_COUNTS";
export const GET_INTEGRATIONS_BY_TYPE = "GET_INTEGRATIONS_BY_TYPE";
export const UPDATE_INTEGRATIONS_BY_TYPE = "UPDATE_INTEGRATIONS_BY_TYPE";

const getAwsIntegrations = (isFetchingPrecentage) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/?isFetchingPrecentage=${!_.isUndefined(
        isFetchingPrecentage
      )}`,
      "GET"
    );
    const integrations = await integrationsReq.json();
    const hasError = !integrationsReq.ok;
    dispatch({
      type: GET_AWS_INTEGRATIONS,
      payload: { hasError, integrations },
    });
    return integrationsReq.ok;
  });
};

const getExternalId = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/externalId`,
      "GET"
    );
    const externalId = await req.json();
    dispatch({
      type: GET_ACCOUNT_EXTERNAL_ID,
      payload: externalId,
    });
    return externalId;
  });
};

const triggerAwsFetching = (integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integrationId}/sync`,
      "POST"
    );
    return integrationsReq.ok;
  });
};
const triggerAzureFetching = (integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/azure/${integrationId}/sync`,
      "POST"
    );
    return integrationsReq.ok;
  });
};

const triggerAwsCftFetching = (integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integrationId}/cftSync`,
      "POST"
    );
    return integrationsReq.ok;
  });
};

const getIntegrationLastSync = (integrationId, provider) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/${provider}/${integrationId}/sync`,
      "GET"
    );
    const data = await integrationsReq?.json();
    return { req: integrationsReq, data };
  });
};


const createAwsIntegration = (
  name,
  accountNumber,
  redactorArn = "",
  externalId,
  isProd,
  fullScanEnabled = true,
  hideError,
  isEventDriven = false,
  eventDrivenRegions = [],
  isIacAutoDiscoverDisabled = false,
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const roleArn = `arn:aws:iam::${accountNumber}:role/firefly-caa-role`;

    const integrationReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws`,
      "POST",
      {
        roleArn,
        name,
        externalId,
        fullScanEnabled,
        isProd,
        redactorArn,
        isEventDriven,
        eventDrivenRegions,
        isIacAutoDiscoverDisabled
      },
      undefined,
      undefined,
      hideError ? false : true
    );
    const data = await integrationReq.json();
    return { req: integrationReq, integration: data?.integration, data };
  });
};

export const getAwsFetchingMetadata = (
  integrationId,
  page,
  ignoreOptInSiteRegions
) => {
  if (_.isUndefined(page)) {
    page = 0;
  }
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const fetchingMetadataReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventory/fetching/aws/${integrationId}`, //TODO: change to new route
      "POST",
      {
        queryPage: page,
        ignoreOptInSiteRegions: ignoreOptInSiteRegions,
      }
    );
    const metadata = await fetchingMetadataReq.json();

    dispatch({
      type: GET_AWS_INTEGRATION_FETCHING_STATUS,
      payload: { hasError: !fetchingMetadataReq.ok, metadata },
    });
    return metadata;
  });
};

const deleteIntegration = (type, id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/${type}/${id}`,
      "DELETE"
    );
    return res.ok;
  });
};
const deleteAwsIntegration = (integration) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integration.toString()}`,
      "DELETE"
    );
    return res.ok;
  });
};

const editAwsIntegration = (integration, name, fetchable, isProd) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integration.toString()}`,
      "PUT",
      { name, fetchable, isProd }
    );
    return res.ok;
  });
};

const getAllBuckets = (integration) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integration}/buckets`,
      "GET"
    );
    dispatch({
      type: GET_ALL_BUCKETS,
      payload: {},
    });
    const buckets = await req.json();

    return { buckets };
  });
};

const getAllS3Objects = (integration, bucket) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integration}/bucket/${bucket}/objects`,
      "GET",
      undefined,
      undefined,
      undefined,
      false
    );

    dispatch({
      type: GET_ALL_S3_OBJECTS,
      payload: {},
    });

    const objects = await res.json();
    return { objects, ok: res.ok };
  });
};

const callTfcAPI = (payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/tfc/call_api`,
      "POST",
      payload
    );

    dispatch({
      type: GET_TFC_WORKSPACES,
      payload,
    });

    const data = await res.json();
    return data;
  });
};

const testSlackIntegration = (webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack/test`,
      "POST",
      { webhookUrl }
    );

    return res.ok;
  });
};

const createSlackWebhhookIntegration = (name, webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack`,
      "POST",
      { name, webhookUrl }
    );

    return res.ok;
  });
};

const createSlackAppIntegration = (name, code) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack/app/`,
      "POST",
      { name, code }
    );

    return res.ok;
  });
};

const deleteSlackIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack/${id}`,
      "DELETE"
    );

    return res.ok;
  });
};

const editSlackIntegration = (id, name) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack/${id}`,
      "PUT",
      { name }
    );

    return res.ok;
  });
};

const getSlackIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack`,
      "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_SLACK_INTEGRATIONS,
      payload: { integrations },
    });
    return req.ok;
  });
};

const getAllSlackChannels = (integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack/appchannels?integrationId=${integrationId}`,
      "GET",
    );

    const channels = await req.json();

    dispatch({
      type: GET_SLACK_CHANNELS,
      payload: { channels },
    });
  });
};

const getSlackChannelsByIds = (integrationId, channel_ids) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/slack/channels-by-ids`,
      "POST",
      {integrationId, channel_ids}
    );

    const channels = await req.json();

    dispatch({
      type: GET_SLACK_CHANNELS,
      payload: { channels },
    });
  });
};

const testGoogleChatIntegration = (webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/google-chat/test`,
      "POST",
      { webhookUrl },
      true,
      null,
      false,
    );

    return res.ok;
  });
};

const getGoogleChatIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/google-chat`,
      "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_GOOGLE_CHAT_INTEGRATIONS,
      payload: { integrations },
    });
    return req.ok;
  });
};

//Webex
const getWebexIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webex`,
      "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_WEBEX_INTEGRATIONS,
      payload: { integrations },
    });
    return req.ok;
  });
};

const createWebexIntegration = (name, code) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webex`,
      "POST",
      { name, code }
    );

    return res.ok;
  });
};

const editWebexIntegration = (id, name) => {
    return action(async (_) => {
        const requestWrapper = new RequestWrapper();

        const res = await requestWrapper.sendRequest(
			`${BACKEND_URL}/integrations/webex/${id}`,
			"PUT",
			{ name }
        );

        return res.ok;
    });
};

const deleteWebexIntegration = (id) => {
	return action(async (_) => {
		const requestWrapper = new RequestWrapper();

		const res = await requestWrapper.sendRequest(
			`${BACKEND_URL}/integrations/webex/${id}`,
			"DELETE"
		);

		return res.ok;
  });
};

const getWebexRooms = (integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webex/${integrationId}/rooms`,
      "GET",
    );

    const rooms = await req.json();

    dispatch({
      type: GET_WEBEX_ROOMS,
      payload: { rooms },
    });
  });
};

const getWebexRoomsById = (integrationId, roomsIds) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webex/${integrationId}/rooms`,
      "POST",
      {roomsIds}
    );

    const channels = await req.json();

    dispatch({
      type: GET_WEBEX_ROOMS,
      payload: { channels },
    });
  });
};

const addBotToWebexRooms = (integrationId, roomsIds, handleError) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webex/${integrationId}/rooms/add-bot`,
      "POST",
      {roomsIds},
      undefined,
      undefined,
      handleError
    );
    return res;
  });
};



const testTeamsIntegration = (webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/teams/test`,
      "POST",
      { webhookUrl }
    );

    return res.ok;
  });
};

const createTeamsIntegration = (name, webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/teams/`,
      "POST",
      { name, webhookUrl }
    );

    return res.ok;
  });
};

const deleteTeamsIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/teams/${id}`,
      "DELETE"
    );

    return res.ok;
  });
};

const editTeamsIntegration = (id, name) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/teams/${id}`,
      "PUT",
      { name }
    );

    return res.ok;
  });
};

const getTeamsIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/teams`,
      "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_TEAMS_INTEGRATIONS,
      payload: { integrations },
    });
    return req.ok;
  });
};

const testWebhookIntegration = (webhookUrl, username, password) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webhook/test`,
      "POST",
      {
        webhookUrl,
        username,
        password,
      }
    );

    return res.ok;
  });
};

const createWebhookIntegration = (name, webhookUrl, username, password) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webhook/`,
      "POST",
      {
        name,
        webhookUrl,
        username,
        password,
      }
    );

    return res.ok;
  });
};

const deleteWebhookIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webhook/${id}`,
      "DELETE"
    );

    return res.ok;
  });
};

const getWebhookIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webhook`,
      "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_WEBHOOK_INTEGRATIONS,
      payload: { integrations },
    });
    return req.ok;
  });
};

const editWebhookIntegration = (id, name) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/webhook/${id}`,
      "PUT",
      { name }
    );

    return res.ok;
  });
};

const testOpsgenieIntegration = (apiKey) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/opsgenie/test`,
      "POST",
      {
        apiKey
      }
    );

    return res.ok;
  });
};

const createOpsgenieIntegration = (name, apiKey, tags) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/opsgenie/`,
      "POST",
      {
        name,
        apiKey,
        tags
      }
    );

    return res.ok;
  });
};

const deleteOpsgenieIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/opsgenie/${id}`,
      "DELETE"
    );

    return res.ok;
  });
};

const getOpsgenieIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/opsgenie`,
      "GET"
    );
    const integrations = await req.json();
    dispatch({
      type: GET_OPSGENIE_INTEGRATIONS,
      payload: { integrations },
    });
    return req.ok;
  });
};

const editOpsgenieIntegration = (id, name) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/opsgenie/${id}`,
      "PUT",
      { name }
    );

    return res.ok;
  });
};

const setAwsIntegs = (awsIntegs, integrationId, percentage) => {
  return action(async (dispatch) => {
    if (!_.isEmpty(awsIntegs)) {
      dispatch({
        type: SET_AWS_INTEGS,
        payload: { awsIntegs },
      });
    } else {
      dispatch({
        type: SET_AWS_INTEGS_PERCENTAGE,
        payload: { integrationId, percentage },
      });
    }
  });
};

const getAllCftStacks = (integration, region) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integration}/stacks/${region}`,
      "GET"
    );

    const stacks = await req.json();

    return { req, stacks };
  });
};

const getAllCftResources = (integration, region, stackName) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integration}/stacks/${stackName}/resources/${region}`,
      "GET",
      undefined,
      undefined,
      undefined,
      false
    );

    return req;
  });
};

const getAllAccountValidStacks = (integration) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/aws/${integration}/allStacks`,
      "GET"
    );

    const stacks = await req.json();

    return { req, stacks };
  });
};

const runFullScan = (integrationId, remoteType) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/fullScan/`,
      "POST",
      {
        integrationId,
        remoteType,
      }
    );

    return req.ok;
  });
};

const scanIntegrationAssetsByType = (providerType, integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/scan-assets/${providerType}/${integrationId}`,
      "GET"
    );

    return req.ok;
  });
};

const scanIntegrationPrimaryProject = (integrationId, accountId, provider) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/${provider}/${integrationId}/sync`,
      "POST",
      {
        accountId
      }
    );
    return req.ok;
  });
};
const scanGcpAssociatedProjects = (integrationId,accountId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/google/${integrationId}/projects-discovery-sync`,
      "POST",
      {
        accountId,
      }
    );

    return req.ok;
  });
};

const getOnPremiseIp = () => {
  return action(async (dispatch, getState) => {
    const requestWrapper = new RequestWrapper();

    const url = `${BACKEND_URL}/vcs/public-ips`;
    const req = await requestWrapper.sendRequest(
      url,
      "GET",
      {},
      undefined,
      undefined,
      false
    );

    if (req.ok) {
      let data = await req.json();
      return dispatch({
        type: GET_ON_PREM_IP,
        payload: data,
      });
    }
    return;
  });
};

const testTorqIntegration = (webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/torq/test`,
      "POST",
      { webhookUrl }
    );

    return res.ok;
  });
};

const createTorqIntegration = (name, webhookUrl) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/torq/`,
      "POST",
      { name, webhookUrl }
    );

    return res;
  });
};

const deleteTorqIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/torq/${id}`,
      "DELETE"
    );

    return res.ok;
  });
};

const editTorqIntegration = (id, name) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/torq/${id}`,
      "PUT",
      { name }
    );

    return res.ok;
  });
};

const getTorqIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/torq`,
      "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_TORQ_INTEGRATIONS,
      payload: { integrations },
    });
    return req.ok;
  });
};

// pagerduty ------------
const createPagerdutyIntegration = (name, apiKey, serviceType) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pagerduty`,
      "POST",
      { name, apiKey, serviceType }
    );

    return res;
  });
};

const deletePagerIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pagerduty/${id}`,
      "DELETE"
    );

    return res?.ok;
  });
};

const editPagerdutyIntegration = (id, name) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pagerduty/${id}`,
      "PUT",
      { name }
    );

    return res.ok;
  });
};

const getPagerdutyIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pagerduty`,
      "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_PAGERDUTY_INTEGRATIONS,
      payload: { integrations },
    });
    return req.ok;
  });
};

const getPagerdutyServices = (integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pagerduty/services/${integrationId}`,
      "GET",
    );

    let services = [];

    if(req?.ok) {
      services = await req.json();
    }

    dispatch({
      type: GET_PAGERDUTY_SERVICES,
      payload: services,
    });
  });
};

const fetchPdAdminIntegrationKey = (integrationId, service) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pagerduty/integration-key`,
      "POST",
      { integrationId, service }
    );

    let keys = [];

    if(req?.ok) {
      keys = await req.json();
    }

    return keys;
  });
};

// Generic Actions 
const createProviderIntegration = (provider = '', payload = {}) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    
    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/${provider}`,
      "POST",
      payload,
      true,
      null,
      true,
    );

    return res;
  });
}

const deleteProviderIntegration = (provider = '', id = '') => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/${provider}/${id}`,
      "DELETE"
    );

    return res?.ok;
  });
}

const editProviderIntegration = (provider = '' ,id = '', name = '', keysToUpdate = {}) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/${provider}/${id}`,
      "PUT",
      { name, ...keysToUpdate }
    );

    return res?.ok;
  });
};

const getAllIntegrationCounts = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/count`,
      "GET",
    );

    let counts = {};

    if(req?.ok) {
      counts = await req.json();
    }

    dispatch({
      type: GET_ALL_INTEGRATION_COUNTS,
      payload: counts,
    });
  });
};

const getAllIntegrationsByType = (type, page, size, query, filters, sorting = {}) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const { isProd, isEventDriven } = filters;

    const requestBody = {
      type,
      page,
      page_size: size,
      filter: query,
      is_prod: isProd,
      is_event_driven: isEventDriven,
      sorting,
    };

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/details/${type}`,
      "POST",
      requestBody
    );

    let response = {};

    if (req?.ok) {
      response = await req.json();
    }

    dispatch({
      type: GET_INTEGRATIONS_BY_TYPE,
      payload: response,
    });
    return response;
  });
};

const getAllIntegrationsByTypeDiscovery = (type) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/discovery/${type}`,
      "GET",
    );

    let response = {};

    if(req?.ok) {
      response = await req.json();
    }
    return response;
  });
};
const scanAllIntegrationsByType = (type) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/scan/${type}`,
      "GET",
    );

    return req?.ok;
  });
};

const getIntegrationResourceCount = (type, integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/details/${type}/${integrationId}/count`,
      "GET",
    );

    let response = {};

    if(req?.ok) {
      response = await req.json();
    }

    return response;
  });
};

const updateIntegrationsById = (integrationId, payload) => {
  return action(async (dispatch, getState) => {
    const state = getState() || {};
    const integs = state.integrationsReducer?.integsByType?.integrations || [];
    const updatedIntegs = integs.map((integration = {}) => { return integration._id === integrationId ? { ...integration, ...payload } : integration });
    const total = updatedIntegs?.length || 0;
    const integsByType = { total, integrations: updatedIntegs , isUpdated: true };

    dispatch({
      type: UPDATE_INTEGRATIONS_BY_TYPE,
      payload: integsByType,
    })
  });
};

const getIntegrationSecret = (id, type) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/${type}/${id}`,
      "GET"
    );

    let response = {}
    if (req?.ok) {
        response = await req.json();
    }

    return response;
  });
};

export {
  getAwsIntegrations,
  getExternalId,
  triggerAwsFetching,
  triggerAwsCftFetching,
  getIntegrationLastSync,
  createAwsIntegration,
  deleteAwsIntegration,
  deleteIntegration,
  editAwsIntegration,
  getAllBuckets,
  getAllS3Objects,
  callTfcAPI,
  testSlackIntegration,
  createSlackWebhhookIntegration,
  createSlackAppIntegration,
  getSlackIntegrations,
  deleteSlackIntegration,
  editSlackIntegration,
  getAllSlackChannels,
  testTeamsIntegration,
  createTeamsIntegration,
  getTeamsIntegrations,
  deleteTeamsIntegration,
  editTeamsIntegration,
  testWebhookIntegration,
  createWebhookIntegration,
  getWebhookIntegrations,
  deleteWebhookIntegration,
  editWebhookIntegration,
  testOpsgenieIntegration,
  createOpsgenieIntegration,
  getOpsgenieIntegrations,
  deleteOpsgenieIntegration,
  editOpsgenieIntegration,
  setAwsIntegs,
  getAllCftStacks,
  getAllCftResources,
  getAllAccountValidStacks,
  runFullScan,
  getOnPremiseIp,
  testTorqIntegration,
  createTorqIntegration,
  deleteTorqIntegration,
  editTorqIntegration,
  getTorqIntegrations,
  getSlackChannelsByIds,
  createPagerdutyIntegration,
  deletePagerIntegration,
  editPagerdutyIntegration,
  getPagerdutyIntegrations,
  getPagerdutyServices,
  fetchPdAdminIntegrationKey,
  createProviderIntegration,
  deleteProviderIntegration,
  editProviderIntegration,
  scanIntegrationPrimaryProject,
  scanGcpAssociatedProjects,
  getAllIntegrationCounts,
  getAllIntegrationsByType,
  getAllIntegrationsByTypeDiscovery,
  scanAllIntegrationsByType,
  scanIntegrationAssetsByType,
  getIntegrationResourceCount,
  updateIntegrationsById,
  getGoogleChatIntegrations,
  testGoogleChatIntegration,
  triggerAzureFetching,
  getWebexIntegrations,
  createWebexIntegration,
  editWebexIntegration,
  deleteWebexIntegration,
  getWebexRooms,
  getWebexRoomsById,
  addBotToWebexRooms,
  getIntegrationSecret
};