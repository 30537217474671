import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { emailRegex } from "../../../utils/validations";
import { renderIps } from "../../../utils/helpers";
import "./JiraEditForm.scss";
import { useTranslation } from "react-i18next";

const JiraEditForm = () => {
  const { t } = useTranslation("integrations");

  return (
    <div className="JiraEditForm">
      <div className="row container">
        <Form.Item
          label={t('jiraForm.domain')}
          name="domain"
          rules={[
            {
              required: true,
              message: t('jiraForm.domain-message'),
            },
          ]}
          className="form-item form-item-wide"
        >
          <Input placeholder="<DomainName>" />
        </Form.Item>
        <span className="form-label">{`.atlassian.net`}</span>
      </div>
      <Form.Item
        label={t('jiraForm.email')}
        name="mail"
        rules={[
          {
            required: true,
            message: t('jiraForm.email-message'),
          },
          {
            pattern: emailRegex.regex,
            message: emailRegex.msg,
          },
        ]}
        className="form-item"
      >
        <Input placeholder="Please fill Jira user email" />
      </Form.Item>
      <Form.Item
        label={t('jiraForm.token')}
        name="token"
        rules={[
          {
            required: true,
            message: t('jiraForm.token-message'),
          },
        ]}
        className="form-item"
      >
        <Input.Password />
      </Form.Item>
      {renderIps()}
    </div>
  );
};

export default JiraEditForm;